import React, { FC, Fragment, useState } from 'react';
import { Button, Card, Col, InputGroup, Row, Form, Alert, Nav, Tab } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useLocation, useSearchParams } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import PageHeader from '../../../layouts/pageheader';
import { Oval } from "react-loader-spinner";

function AddSupplier() {
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate();
  const schema = yup.object().shape({

  })
  const {
    register,
    handleSubmit,
    reset,
    clearErrors,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const onSubmit = async (data) => {
    const upload = {
      userId: "string",
      supplierName: data?.supplierName,
      supplierAddress: data?.supplierAddress,
      supplierPhoneNumber: data?.supplierPhoneNumber,
      supplierEmail: data?.supplierEmail,
      supplierCurrencyCode: data?.supplierCurrencyCode,
      supplierId: 0
    }
    setLoading(true)
    try {
      const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/Supplier/CreateSupplier`, upload);
      if (response.data.status == 1) {
        toast.success(response.data.message);
        navigate('/supplier-list')
        setLoading(false)
      }

    } catch (error) {
      setLoading(false)
      console.log(error);
    }
  }
  const [img, setImg] = useState()
  const handleImageChange = (e) => {
    setImg(e.target.files[0])

  };
  return (
    <Fragment>
      <Fragment>
        <div className='page-header'>
          <div className="page-leftheader">
            <h4 className="page-title mb-0">Add Supplier</h4>
          </div>
          <div className="page-rightheader">
            <div className='d-flex align-items-center'>

            </div>
          </div>
        </div>
      </Fragment>
      <Row>
        <Col xl={4} md={12} sm={12}>
        </Col>
        <Col xl={4} md={12} sm={12}>
          <Card>
            <Card.Body>
              <Form onSubmit={handleSubmit(onSubmit)}>
                <Form.Group as={Col} md="12" controlId="validationCustom01">
                  <Form.Control
                    type="text"
                    name='supplierName'
                    placeholder="Name"
                    {...register("supplierName")}
                  />
                  <p className="errorMsg pl-3 mt-2">{errors.supplierName ? errors.supplierName.message : ''}</p>
                </Form.Group>
                <Form.Group as={Col} md="12" controlId="validationCustom01">
                  <Form.Control
                    type="text"
                    name='supplierEmail'
                    placeholder="Email"
                    {...register("supplierEmail")}
                  />
                  <p className="errorMsg pl-3 mt-2">{errors.supplierEmail ? errors.supplierEmail.message : ''}</p>
                </Form.Group>
                <Form.Group as={Col} md="12" controlId="validationCustom01">
                  <Form.Control
                    type="text"
                    name='supplierPhoneNumber'
                    placeholder="Phone"
                    {...register("supplierPhoneNumber")}
                  />
                  <p className="errorMsg pl-3 mt-2">{errors.supplierPhoneNumber ? errors.supplierPhoneNumber.message : ''}</p>
                </Form.Group>
                <Form.Group as={Col} md="12" controlId="validationCustom01">
                  <Form.Control
                    type="text"
                    name='supplierAddress'
                    placeholder="Address"
                    {...register("supplierAddress")}
                  />
                  <p className="errorMsg pl-3 mt-2">{errors.supplierAddress ? errors.supplierAddress.message : ''}</p>
                </Form.Group>
                <Form.Group as={Col} md="12" controlId="validationCustom01">
                  <Form.Control
                    type="text"
                    name='supplierCurrencyCode'
                    placeholder="Currency"
                    {...register("supplierCurrencyCode")}
                  />
                  <p className="errorMsg pl-3 mt-2">{errors.supplierCurrencyCode ? errors.supplierCurrencyCode.message : ''}</p>
                </Form.Group>
                <div className="d-flex justify-content-center formfilter mt-5">
                  <Button type="submit" className="mx-2">{loading ?
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "25px" }}>
                      <Oval

                        heigth="20"
                        width="20"
                        color='#12C412'
                        arialLabel='loading'
                      />
                    </div> : "Submit"}</Button>
                </div>
              </Form>
            </Card.Body>
          </Card>
        </Col>
        <Col xl={4} md={12} sm={12}>
        </Col>
      </Row>

    </Fragment>
  )
}

export default AddSupplier
