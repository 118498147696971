import React, { FC, Fragment, useState, useEffect } from 'react';
import { Button, Card, Col, InputGroup, Row, Form, Alert, Nav, Tab } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useLocation, useSearchParams } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
// import PageHeader from '../../../layouts/pageheader';
import { Oval } from "react-loader-spinner";
import Select from 'react-select';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function AddCategory() {
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate();
    const schema = yup.object().shape({
        categoryName: yup.string().required("Name required"),
    })
    const {
        register,
        handleSubmit,
        reset,
        clearErrors,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    });
    const onSubmit = async (data) => {
        const upload = {
            userId: "string",
            categoryName: data?.categoryName,
            categoryId: 0,
            parentId: 0
        }
        setLoading(true)
        try {
            const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/ProductCategory/CreateProductCategory`, upload);
            if (response.data.status == 1) {
                toast.success(response.data.message);
                navigate('/category-list')
                setLoading(false)
            }

        } catch (error) {
            setLoading(false)
            console.log(error);
        }
    }
  return (
    <Fragment>
    <Fragment>
        <div className='page-header'>
            <div className="page-leftheader">
                <h4 className="page-title mb-0">Add Category</h4>
            </div>
            <div className="page-rightheader">
                <div className='d-flex align-items-center'>

                </div>
            </div>
        </div>
    </Fragment>
    <Row>
        <Col xl={4} md={12} sm={12}>
        </Col>
        <Col xl={4} md={12} sm={12}>
            <Card>
                <Card.Body>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <Form.Group as={Col} md="12" controlId="validationCustom01">
                            <Form.Control
                                type="text"
                                name='categoryName'
                                placeholder="Name"
                                {...register("categoryName")}
                            />
                            <p className="errorMsg pl-3 mt-2">{errors.brandName ? errors.brandName.message : ''}</p>
                        </Form.Group>
                        <div className="d-flex justify-content-center formfilter mt-5">
                            <Button type="submit" className="mx-2">{loading ?
                                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "25px" }}>
                                    <Oval

                                        heigth="20"
                                        width="20"
                                        color='#12C412'
                                        arialLabel='loading'
                                    />
                                </div> : "Submit"}</Button>
                        </div>
                    </Form>
                </Card.Body>
            </Card>
        </Col>
        <Col xl={4} md={12} sm={12}>
        </Col>
    </Row>

</Fragment>
  )
}

export default AddCategory
