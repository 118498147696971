import React, { Fragment } from 'react'
import Main_dashboard from '../Components/dashboard/Main_dashboard';
import DataTable from '../Components/list/datatable';
import AddProducts from '../Components/addproducts/addproducts';
import DesignationList from '../Components/Master/Designation/DesignationList';
import AddDesignation from '../Components/Master/Designation/AddDesignation';
import EditDesignation from '../Components/Master/Designation/EditDesignation';
import Departmentlist from '../Components/Master/Department/Departmentlist';
import AddDepartment from '../Components/Master/Department/AddDepartment';
import EditDepartment from '../Components/Master/Department/EditDepartment';
import EditCompany from '../Components/Master/Company/EditCompany';
import AddCompany from '../Components/Master/Company/AddCompany';
import Companylist from '../Components/Master/Company/Companylist';
import Supplierlist from '../Components/Master/Supplier/Supplierlist';
import EditSupplier from '../Components/Master/Supplier/EditSupplier';
import AddSupplier from '../Components/Master/Supplier/AddSupplier';
import Unitlist from '../Components/Master/Unit/Unitlist';
import AddUnit from '../Components/Master/Unit/AddUnit';
import EditUnit from '../Components/Master/Unit/EditUnit';
import Projectlist from '../Components/Master/Project/Projectlist';
import EditProjects from '../Components/Master/Project/EditProjects';
import AddProject from '../Components/Master/Project/AddProject';
import OrderDetails from '../Components/Order/order-details/OrderDetails';
import SupplierContactlist from '../Components/Master/SupplierContact/SupplierContactlist';
import AddSupplierContact from '../Components/Master/SupplierContact/AddSupplierContact';
import EditSupplierContact from '../Components/Master/SupplierContact/EditSupplierContact';
import Employelist from '../Components/Master/Employe/Employelist';
import AddEmploye from '../Components/Master/Employe/AddEmploye';
import EditEmploye from '../Components/Master/Employe/EditEmploye';
import AddPO from '../Components/PO/AddPO';
import POlist from '../Components/PO/POlist';
import EditPO from '../Components/PO/EditPO';
import AddProduct from '../Components/Master/Product/AddProduct';
import Productlist from '../Components/Master/Product/Productlist';
import EditProduct from '../Components/Master/Product/EditProduct';
import AddBrand from '../Components/Master/ProductBrand/AddBrand';
import EditBrand from '../Components/Master/ProductBrand/EditBrand';
import Brandlist from '../Components/Master/ProductBrand/Brandlist';
import CategoryList from '../Components/Master/Category/CategoryList';
import EditCategory from '../Components/Master/Category/EditCategory';
import AddCategory from '../Components/Master/Category/AddCategory';
import { ResponsiveDataTable } from '../Components/dashboard/dashboarddata/boarddata';
import AddSr from '../Components/SR/AddSr';
import EditSr from '../Components/SR/EditSr';
import SrList from '../Components/SR/SrList';
import ApprovePo from '../Components/PO/ApprovePo';
import AddMR from '../Components/MR/AddMR';
import ApproveMr from '../Components/MR/ApproveMr';
import EditMr from '../Components/MR/EditMr';
import MRlist from '../Components/MR/MRlist';



//--------------
import ExpenseTypelist from '../Components/Master/ExpenseType/ExpenseTypeList';
import AddExpenseType from '../Components/Master/ExpenseType/AddExpenseType';
import EditExpenseType from '../Components/Master/ExpenseType/EditExpenseType';

import ExpenseList from '../Components/Expense/Expenselist';
import AddExpense from '../Components/Expense/AddExpense';
import EditExpense from '../Components/Expense/EditExpense';

import MakeList from '../Components/Master/Make/Makelist';
import AddMake from '../Components/Master/Make/AddMake';
import EditMake from '../Components/Master/Make/EditMake';

import ModelList from '../Components/Master/Model/Modellist';
import AddModel from '../Components/Master/Model/AddModel';
import EditModel from '../Components/Master/Model/EditModel';


import AddTrip from '../Components/Trip/AddTrip';
import TripList from '../Components/Trip/TripList';
import EditTrip from '../Components/Trip/EditTrip';

import AddPayment from '../Components/Payment/AddPayment';
import PaymentList from '../Components/Payment/Paymentlist';
import EditPayment from '../Components/Payment/EditPayment';

import AddReceipt from '../Components/Receipt/AddReceipt';
import ReceiptList from '../Components/Receipt/ReceiptList';
import EditReceipt from '../Components/Receipt/EditReceipt';




export const RouterData = [

    { path: `dashboard`, element: <Main_dashboard /> },
    // { path: `add-product`, element: <AddProducts /> },
    // { path: `product-list`, element: <DataTable /> },
    { path: `designation-list`, element: <DesignationList /> },
    { path: `add-designation`, element: <AddDesignation /> },
    { path: `edit-designation`, element: <EditDesignation /> },
    { path: `department-list`, element: <Departmentlist /> },
    { path: `add-department`, element: <AddDepartment /> },
    { path: `edit-department`, element: <EditDepartment /> },
    { path: `company-list`, element: <Companylist /> },
    { path: `add-company`, element: <AddCompany /> },
    { path: `edit-company`, element: <EditCompany /> },
    { path: `supplier-list`, element: <Supplierlist /> },
    { path: `add-supplier`, element: <AddSupplier /> },
    { path: `edit-supplier`, element: <EditSupplier /> },
    { path: `unit-list`, element: <Unitlist /> },
    { path: `add-unit`, element: <AddUnit /> },
    { path: `edit-unit`, element: <EditUnit /> },
    { path: `project-list`, element: <Projectlist /> },
    { path: `add-project`, element: <AddProject /> },
    { path: `edit-project`, element: <EditProjects /> },
    { path: `order-details`, element: <OrderDetails /> },
    { path: `supplier-contact-list`, element: <SupplierContactlist /> },
    { path: `add-supplier-contact`, element: <AddSupplierContact /> },
    { path: `edit-supplier-contact`, element: <EditSupplierContact /> },
    { path: `employe-list`, element: <Employelist /> },
    { path: `add-employe`, element: <AddEmploye /> },
    { path: `edit-employe`, element: <EditEmploye /> },
    { path: `MR`, element: <AddMR /> },    
    { path: `MR-list`, element: <MRlist /> },  
    { path: `edit-mr`, element: <EditMr /> }, 
    { path: `PO`, element: <AddPO /> },    
    { path: `PO-list`, element: <POlist /> },  
    { path: `edit-PO`, element: <EditPO /> },  
    { path: `add-product`, element: <AddProduct /> },    
    { path: `product-list`, element: <Productlist /> },  
    { path: `edit-product`, element: <EditProduct /> },  
    { path: `add-brand`, element: <AddBrand /> },    
    { path: `brand-list`, element: <Brandlist /> },  
    { path: `edit-brand`, element: <EditBrand /> }, 
    { path: `add-category`, element: <AddCategory /> },    
    { path: `category-list`, element: <CategoryList /> },  
    { path: `edit-category`, element: <EditCategory /> },  
    { path: `add-srv`, element: <AddSr /> },    
    { path: `srv-list`, element: <SrList /> },  
    { path: `edit-srv`, element: <EditSr /> }, 
    { path: `approve-mr`, element: <ApproveMr /> }, 
    { path: `approve-po`, element: <ApprovePo /> },


    //-----
    { path: `expenseType-list`, element: <ExpenseTypelist /> }, 
    { path: `add-expenseType`, element: <AddExpenseType /> }, 
    { path: `edit-expenseType`, element: <EditExpenseType /> },

    { path: `expense-list`, element: <ExpenseList /> }, 
    { path: `add-expense`, element: <AddExpense /> }, 
    { path: `edit-expense`, element: <EditExpense /> },

    { path: `make-list`, element: <MakeList /> }, 
    { path: `add-make`, element: <AddMake /> }, 
    { path: `edit-make`, element: <EditMake /> },

    { path: `model-list`, element: <ModelList /> }, 
    { path: `add-model`, element: <AddModel /> }, 
    { path: `edit-model`, element: <EditModel /> },


    { path: `add-trip`, element: <AddTrip /> }, 
    { path: `edit-trip`, element: <EditTrip /> }, 
    { path: `trip-list`, element: <TripList /> }, 

    { path: `add-payment`, element: <AddPayment /> }, 
    { path: `edit-payment`, element: <EditPayment /> }, 
    { path: `payment-list`, element: <PaymentList /> }, 


    { path: `add-receipt`, element: <AddReceipt /> }, 
    { path: `edit-receipt`, element: <EditReceipt /> }, 
    { path: `receipt-list`, element: <ReceiptList /> }, 

];