import React, { FC, Fragment, useState, useEffect } from 'react';
import { Button, Card, Col, InputGroup, Row, Form, Alert, Nav, Tab } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { Oval } from "react-loader-spinner";
import Select from 'react-select';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import edit from "../../assets/images/edit.svg";
import del from "../../assets/images/trash.svg";
import plus from "../../assets/images/plus.png";

function EditSr() {
    const location = useLocation();
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate();
    const schema = yup.object().shape({
    })
    const {
      register,
      handleSubmit,
      reset,
      setValue,
      clearErrors,
      formState: { errors },
    } = useForm({
      resolver: yupResolver(schema),
    });
    useEffect(() => {
      units()
      Polist()
      projectlist()
      productslist()
      prolistbyid()
    }, [])
    const [msg1, setMsg1] = useState();
    const [msg2, setMsg2] = useState();
    const [startDate, setStartDate] = useState(new Date());
    const [unitlist, setUnits] = useState([])
    const [prolist, setPro] = useState([])
    const [projectlists, setProjectList] = useState([])
    const [selectedsupplier, setSelectedSupplier] = useState([])
    const [productprice, setProductPrice] = useState([])
    const [selectedprojects, setSelectedProjects] = useState([])
    const [selectunitU, setSelectunitU] = useState([])
    const [supplierlist, setSupplierList] = useState([])
    const [selectunitPro, setSelectunitPro] = useState([])
    const [selectunitqty, setSelectunitQty] = useState('')
    const [productlistUnits, setAddProductList] = useState([])
    const prolistbyid = () => {
        axios({
          url: `${process.env.REACT_APP_SERVER_URL}/api/SRV/GetSRVById?SRVId=${parseInt(location.state.id)}`,
          method: 'GET',
        }).then((res) => {
          setValue("srvNumber", res.data.data.srvNumber)
          setStartDate(res.data.data.srvDate?new Date(res.data.data.srvDate):new Date())
          setSelectedSupplier({
            label:res.data.data.supplierName,
            value:res.data.data.srvSupplierId
          })
          setSelectedProjects({
            label:res.data.data.projectName,
            value:res.data.data.srvProjectId
          })
          setAddProductList(res.data.data.srVdetailModel)
        })
          .catch((error) => {
            setLoading(false)
          })
      }
    const units = async (value) => {
      axios({
        url: `${process.env.REACT_APP_SERVER_URL}/api/Unit/GetUnitList`,
        method: 'GET',
      }).then((response) => {
        const formattedData = response.data.data.map(item => ({
          label: item.unitCode,
          value: item.unitId
        }));
        setUnits(formattedData)
      })
        .catch((error) => {
        })
    };
    const productslist = async (value) => {
      axios({
        url: `${process.env.REACT_APP_SERVER_URL}/api/Product/GetProductList?ProductCode&UnitConvertion`,
        method: 'GET',
      }).then((response) => {
        const formattedData = response.data.data.map(item => ({
          label: item.productName,
          value: item.productId
        }));
        setPro(formattedData)
      })
        .catch((error) => {
        })
    };
    const Polist = async (value) => {
      axios({
        url: `${process.env.REACT_APP_SERVER_URL}/api/PO/GetPOList`,
        method: 'GET',
      }).then((response) => {
        const formattedData = response.data.data.map(item => ({
          label: item.poNumber,
          value: item.poId
        }));
        setSupplierList(formattedData)
      })
        .catch((error) => {
        })
    };
    const projectlist = async (value) => {
      axios({
        url: `${process.env.REACT_APP_SERVER_URL}/api/Project/GetProjectList`,
        method: 'GET',
      }).then((response) => {
        const formattedData = response.data.data.map(item => ({
          label: item.projectName,
          value: item.projectId
        }));
        setProjectList(formattedData)
      })
        .catch((error) => {
        })
    };
    const onSubmit = async (data) => {
      const upload = {
        userId: "string",
        poId:selectedsupplier.value,
        srvDate: startDate,
        srvId:parseInt(location.state.id),
        srvNumber: data?.poNumber,
        currencyCode: data?.currencyCode,
        pO_details: productlistUnits
      }
      setLoading(true)
      try {
        const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/PO/CreatePO`, upload);
        if (response.data.status == 1) {
          toast.success(response.data.message);
          navigate('/PO-list')
          setLoading(false)
        }
  
      } catch (error) {
        setLoading(false)
        console.log(error);
      }
    }
  
    const handleSelectSupplier = (selectedOption) => {
      setSelectedSupplier(selectedOption);
    };
    const handleSelectProject = (selectedOption) => {
      setSelectedProjects(selectedOption);
    };
    const handleUnitProduct = (selectedOption) => {
      setSelectunitPro(selectedOption)
    }
    const handleUnitUnit = (selectedOption) => {
      setSelectunitU(selectedOption)
    }
    const handleAddProduct = (e) => {
      if (selectunitPro) {
        setAddProductList([...productlistUnits, {
          productname: selectunitPro?.label,
          prodId: selectunitPro?.value,
          altunitname: selectunitU?.label,
          unitid: selectunitU?.value,
          conversionfactor: selectunitqty,
          prodprice:productprice
        }]);
        // Reset the input values
        setSelectunitPro(null);
        setSelectunitU(null);
        setSelectunitQty('');
        setProductPrice('')
      }
    }
    const deletefunction = (id) => {
      let indexToDelete = id;
  
      if (indexToDelete >= 0 && indexToDelete < productlistUnits.length) {
        // Create a new array without the element at the specified index
        const updatedProductList = [...productlistUnits.slice(0, indexToDelete), ...productlistUnits.slice(indexToDelete + 1)];
  
        // Update the state with the new array
        setAddProductList(updatedProductList);
      } else {
        console.log("Index out of range");
      }
    };
  return (
    <>
    <Fragment>
      <Fragment>
        <div className='page-header'>
          <div className="page-leftheader">
            <h4 className="page-title mb-0">EDIT SRV</h4>
          </div>
          <div className="page-rightheader">
            <div className='d-flex align-items-center'>

            </div>
          </div>
        </div>
      </Fragment>
      <Row>
        <Col xl={12} md={12} sm={12}>
          <Card>
            <Card.Body>
              <Form onSubmit={handleSubmit(onSubmit)}>
                <Row>
                  <Form.Group as={Col} md="3" controlId="validationCustom01">
                  <Form.Label>SRV Number</Form.Label>
                    <Form.Control
                      type="text"
                      name='srvNumber'
                      placeholder="SRV Number"
                      {...register("srvNumber")}
                    />
                    <p className="errorMsg pl-3 mt-2">{errors.srvNumber ? errors.srvNumber.message : ''}</p>
                  </Form.Group>
                  <Form.Group as={Col} md="3" controlId="validationCustom02">
                  <Form.Label>SRV Date</Form.Label>
                    <div style={{ border: '1px solid #f3f3f3', width: '100%', padding: 8 }}>
                      <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} />
                    </div>
                  </Form.Group>
                  <Form.Group as={Col} md="3" controlId="validationCustom01">
                  <Form.Label>Supplier</Form.Label>
                    <Select
                      value={selectedsupplier}
                      onChange={handleSelectSupplier}
                      options={supplierlist}
                      isSearchable
                      placeholder="Search PO"
                    />
                    <p className="errorMsg pl-3 mt-2">{msg1}</p>
                  </Form.Group>
                  <Form.Group as={Col} md="3" controlId="validationCustom01">
                  <Form.Label>Currency</Form.Label>
                    <Form.Control
                      type="text"
                      name='currencyCode'
                      placeholder="Currency"
                      {...register("currencyCode")}
                    />
                    <p className="errorMsg pl-3 mt-2">{errors.currencyCode ? errors.currencyCode.message : ''}</p>
                  </Form.Group>
                </Row>
                <div className="row card custom-card">
                  <div className="table-responsive col-12">
                    <table className="gridjs-table mt-3">
                      <thead>
                        <tr>
                          <th className='gridjs-th'>Product</th>
                          <th className='gridjs-th'>Unit</th>
                          <th className='gridjs-th'>Quantity</th>
                          <th className='gridjs-th'>Price</th>
                          <th className='gridjs-th'>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {productlistUnits?.map((contact, index) => {
                          return (
                            <tr key={index}>
                              <td className="gridjs-td">{contact.productname}</td>
                              <td className="gridjs-td">{contact.altunitname}</td>
                              <td className="gridjs-td">{contact.conversionfactor}</td>
                              <td className="gridjs-td">{contact.prodprice}</td>
                              <td className="gridjs-td">
                                <div className="hstack gap-2 fs-15">
                                  <a className="btn btn-icon btn-sm btn-light" style={{ marginLeft: 10, marginRight: 10 }} onClick={() => deletefunction(index)}><img src={del} /></a>
                                </div>
                              </td>
                            </tr>
                          )
                        })}

                      </tbody>
                    </table>
                    <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 15, marginBottom: 15 }}>
                      <img src={plus} style={{ width: 50, height: 50 }} data-bs-toggle="modal" data-bs-target="#exampleModal" />
                    </div>
                  </div>
                </div>


                <div className="d-flex justify-content-center formfilter mt-5">
                  <Button type="submit" className="mx-2">{loading ?
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "25px" }}>
                      <Oval

                        heigth="20"
                        width="20"
                        color='#12C412'
                        arialLabel='loading'
                      />
                    </div> : "Submit"}</Button>
                </div>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>

    </Fragment>
    <div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">Add Product</h5>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body">
            <div className="row mt-1">
              <div className="col-12">
                <label className='mt-1'>product</label>
                <Select
                  value={selectunitPro}
                  onChange={handleUnitProduct}
                  options={prolist}
                  isSearchable
                  placeholder="Search products"
                />
              </div>
              <div className="col-12">
                <label className='mt-1'>Unit</label>
                <Select
                  value={selectunitU}
                  onChange={handleUnitUnit}
                  options={unitlist}
                  isSearchable
                  placeholder="Search Units"
                />
              </div>
              <div className="col-12">
                <label className='mt-1'>Qty</label>
                <Form.Control
                  type="text"
                  name='qty'
                  value={selectunitqty}
                  placeholder="Quantity"
                  onChange={(e) => setSelectunitQty(e.target.value)}
                />
              </div>
              <div className="col-12">
                <label className='mt-1'>Price</label>
                <Form.Control
                  type="text"
                  name='prodcost'
                  value={productprice}
                  placeholder="Price"
                  onChange={(e) => setProductPrice(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-primary" data-bs-dismiss="modal" aria-label="Close" onClick={handleAddProduct}>Add</button>
          </div>
        </div>
      </div>
    </div>
  </>
  )
}

export default EditSr
