import React from 'react';

const MenuItems = [
    {
        menutitle: "Home",
        id: 1,
        Items: [
            {
                path: `dashboard`,
                // icon: (<svg xmlns="http://www.w3.org/2000/svg" className="side-menu__icon" width="24" height="24" viewBox="0 0 24 24"><path d="M3 13h1v7c0 1.103.897 2 2 2h12c1.103 0 2-.897 2-2v-7h1a1 1 0 0 0 .707-1.707l-9-9a.999.999 0 0 0-1.414 0l-9 9A1 1 0 0 0 3 13zm7 7v-5h4v5h-4zm2-15.586 6 6V15l.001 5H16v-5c0-1.103-.897-2-2-2h-4c-1.103 0-2 .897-2 2v5H6v-9.586l6-6z" /></svg>),
                type: 'link',
                active: false,
                selected: false,
                title: 'Dashboard'
            },
        ]
    },
    {
        menutitle: "Menu",
        id: 2,
        Items: [

            {
                path: `trip-list`,
                // icon: (<svg xmlns="http://www.w3.org/2000/svg" className="side-menu__icon" width="24" height="24" viewBox="0 0 24 24"><path d="M3 13h1v7c0 1.103.897 2 2 2h12c1.103 0 2-.897 2-2v-7h1a1 1 0 0 0 .707-1.707l-9-9a.999.999 0 0 0-1.414 0l-9 9A1 1 0 0 0 3 13zm7 7v-5h4v5h-4zm2-15.586 6 6V15l.001 5H16v-5c0-1.103-.897-2-2-2h-4c-1.103 0-2 .897-2 2v5H6v-9.586l6-6z" /></svg>),
                type: 'link',
                active: false,
                selected: false,
                title: 'Trip'
            },

            {
                path: `payment-list`,
                // icon: (<svg xmlns="http://www.w3.org/2000/svg" className="side-menu__icon" width="24" height="24" viewBox="0 0 24 24"><path d="M3 13h1v7c0 1.103.897 2 2 2h12c1.103 0 2-.897 2-2v-7h1a1 1 0 0 0 .707-1.707l-9-9a.999.999 0 0 0-1.414 0l-9 9A1 1 0 0 0 3 13zm7 7v-5h4v5h-4zm2-15.586 6 6V15l.001 5H16v-5c0-1.103-.897-2-2-2h-4c-1.103 0-2 .897-2 2v5H6v-9.586l6-6z" /></svg>),
                type: 'link',
                active: false,
                selected: false,
                title: 'Payment'
            },

            {
                path: `expensetype-list`,
                // icon: (<svg xmlns="http://www.w3.org/2000/svg" className="side-menu__icon" width="24" height="24" viewBox="0 0 24 24"><path d="M3 13h1v7c0 1.103.897 2 2 2h12c1.103 0 2-.897 2-2v-7h1a1 1 0 0 0 .707-1.707l-9-9a.999.999 0 0 0-1.414 0l-9 9A1 1 0 0 0 3 13zm7 7v-5h4v5h-4zm2-15.586 6 6V15l.001 5H16v-5c0-1.103-.897-2-2-2h-4c-1.103 0-2 .897-2 2v5H6v-9.586l6-6z" /></svg>),
                type: 'link',
                active: false,
                selected: false,
                title: 'Expense Type'
            },

            {
                path: `receipt-list`,
                // icon: (<svg xmlns="http://www.w3.org/2000/svg" className="side-menu__icon" width="24" height="24" viewBox="0 0 24 24"><path d="M3 13h1v7c0 1.103.897 2 2 2h12c1.103 0 2-.897 2-2v-7h1a1 1 0 0 0 .707-1.707l-9-9a.999.999 0 0 0-1.414 0l-9 9A1 1 0 0 0 3 13zm7 7v-5h4v5h-4zm2-15.586 6 6V15l.001 5H16v-5c0-1.103-.897-2-2-2h-4c-1.103 0-2 .897-2 2v5H6v-9.586l6-6z" /></svg>),
                type: 'link',
                active: false,
                selected: false,
                title: 'Receipt'
            },
            

            // {
            //     path: `Main`,
            //     icon: (<svg width="20"
            //     height="20" className="side-menu__icon" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 18">
            //     <path stroke="currentColor" strokeLinecap="round" stroke-linejoin="round" stroke-width="2" d="M11 14h6m-3 3v-6M1.857 1h4.286c.473 0 .857.384.857.857v4.286A.857.857 0 0 1 6.143 7H1.857A.857.857 0 0 1 1 6.143V1.857C1 1.384 1.384 1 1.857 1Zm10 0h4.286c.473 0 .857.384.857.857v4.286a.857.857 0 0 1-.857.857h-4.286A.857.857 0 0 1 11 6.143V1.857c0-.473.384-.857.857-.857Zm-10 10h4.286c.473 0 .857.384.857.857v4.286a.857.857 0 0 1-.857.857H1.857A.857.857 0 0 1 1 16.143v-4.286c0-.473.384-.857.857-.857Z"/>
            //   </svg>),
            //     type: 'sub',
            //     active: false,
            //     selected: false,
            //     title: 'Main',
            //     children: [
            //         { path: `add-trip`, type: 'link', active: false, selected: false, title: 'Add Trip' },
            //         { path: `trip-list`, type: 'link', active: false, selected: false, title: 'Trip List' },
            //         { path: `payment-list`, type: 'link', active: false, selected: false, title: 'Payment ' },
            //         { path: `expensetype-list`, type: 'link', active: false, selected: false, title: 'Expense Type' },
            //         { path: `receipt-list`, type: 'link', active: false, selected: false, title: 'Receipt' }, 
            //     ],
            // },
        ],
    },
];
export default MenuItems;

