import React, { FC, Fragment, useState, useEffect } from 'react';
import { Button, Card, Col, InputGroup, Row, Form, Alert, Nav, Tab } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";
import { toast } from "react-toastify";
import { Oval } from "react-loader-spinner";
import Select from 'react-select';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import del from "../../../assets/images/trash.svg";
import plus from "../../../assets/images/plus.png";
import { useLocation } from "react-router-dom";

function EditProduct() {
  const [loading, setLoading] = useState(false)
  const location = useLocation();
  const navigate = useNavigate();
  const schema = yup.object().shape({
  })
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  useEffect(() => {
    units()
    Brands()
    categorys()
    productslist()
    prolistbyid()
  }, [])
  const [msg1, setMsg1] = useState();
  const [msg2, setMsg2] = useState();
  const [msg3, setMsg3] = useState();
  const [msg4, setMsg4] = useState();
  const [msg5, setMsg5] = useState();
  const [selectedstatus, setSelectedStatus] = useState([]);
  const [startDate, setStartDate] = useState();
  const [startDate1, setStartDate1] = useState();
  const [department, setDepartment] = useState([])
  const [unitlist, setUnits] = useState([])
  const [prolist, setPro] = useState([])
  const [categorylist, setCategoryList] = useState([])
  const [selecteddepartment, setSelectedDepartment] = useState([])
  const [selectedunitlist, setSelectedunitlist] = useState([])
  const [selectedcategory, setSelectedCategory] = useState([])
  const [selectunitU, setSelectunitU] = useState([])
  const [selectunitPro, setSelectunitPro] = useState([])
  const [selectunitqty, setSelectunitQty] = useState('')
  const [productlistUnits, setAddProductList] = useState([])
  const prolistbyid = () => {
    axios({
      url: `${process.env.REACT_APP_SERVER_URL}/api/Product/GetProductById?productid=${parseInt(location.state.id)}`,
      method: 'GET',
    }).then((res) => {
      setValue("productCode", res.data.data.productCode)
      setValue("productName", res.data.data.productName)
      setValue("productDescription", res.data.data.productDescription)
      setValue("productColour", res.data.data.productColour)
      setValue("ProductLength", res.data.data.productLength)
      setValue("ProductGrade", res.data.data.productGrade)
      setValue("ProductDrawing", res.data.data.productDrawing)
      setValue("ProductReference", res.data.data.productReference)
      setValue("ProductDeliveryStation", res.data.data.productDeliveryStation)
      setValue("costPrice", res.data.data.costPrice)
      setAddProductList(res.data.data.unitConvertion)
      setSelectedStatus({
        label: res.data.data.status === 0 ? 'Active' : 'InActive',
        value: res.data.data.status
      })
      axios({
        url: `${process.env.REACT_APP_SERVER_URL}/api/Unit/GetUnitById?unitId=${parseInt(res.data.data.unitId)}`,
        method: 'GET',
      }).then((res) => {
        setSelectedunitlist({
          label: res.data.data.unitCode,
          value: res.data.data.unitId
        })
      })
        .catch((error) => {
          setLoading(false)
        })
      axios({
        url: `${process.env.REACT_APP_SERVER_URL}/api/ProductCategory/GetProductCategoryById?CategoryId=${parseInt(res.data.data.categoryId)}`,
        method: 'GET',
      }).then((res) => {
        setSelectedCategory({
          label: res.data.data.categoryName,
          value: res.data.data.categoryId
        })
      })
        .catch((error) => {
          setLoading(false)
        })
      axios({
        url: `${process.env.REACT_APP_SERVER_URL}/api/ProductBrand/GetProductBrandById?brandid=${parseInt(res.data.data.brandId)}`,
        method: 'GET',
      }).then((res) => {
        setSelectedDepartment({
          label: res.data.data.brandName,
          value: res.data.data.brandId
        })
      })
        .catch((error) => {
          setLoading(false)
        })
    })
      .catch((error) => {
        setLoading(false)
      })
  }
  const units = async (value) => {
    axios({
      url: `${process.env.REACT_APP_SERVER_URL}/api/Unit/GetUnitList`,
      method: 'GET',
    }).then((response) => {
      const formattedData = response.data.data.map(item => ({
        label: item.unitCode,
        value: item.unitId
      }));
      setUnits(formattedData)
    })
      .catch((error) => {
      })
  };
  const productslist = async (value) => {
    axios({
      url: `${process.env.REACT_APP_SERVER_URL}/api/Product/GetProductList?ProductCode&UnitConvertion`,
      method: 'GET',
    }).then((response) => {
      const formattedData = response.data.data.map(item => ({
        label: item.productName,
        value: item.productId
      }));
      setPro(formattedData)
    })
      .catch((error) => {
      })
  };
  const Brands = async (value) => {
    axios({
      url: `${process.env.REACT_APP_SERVER_URL}/api/ProductBrand/GetProductBrandList`,
      method: 'GET',
    }).then((response) => {
      const formattedData = response.data.data.map(item => ({
        label: item.brandName,
        value: item.brandId
      }));
      setDepartment(formattedData)
    })
      .catch((error) => {
      })
  };
  const categorys = async (value) => {
    axios({
      url: `${process.env.REACT_APP_SERVER_URL}/api/ProductCategory/GetProductCategoryList`,
      method: 'GET',
    }).then((response) => {
      const formattedData = response.data.data.map(item => ({
        label: item.categoryName,
        value: item.categoryId
      }));
      setCategoryList(formattedData)
    })
      .catch((error) => {
      })
  };
  const onSubmit = async (data) => {
    const upload = {
      userId: "string",
      productName: data?.productName,
      productCode: data?.productCode,
      productDescription: data?.productDescription,
      unitId: selectedunitlist?.value,
      categoryId: selectedcategory?.value,
      brandId: selecteddepartment?.value,
      productColour: data?.productColour,
      productImage: "string",
      productStatus: selectedstatus?.values,
      productId: parseInt(location.state.id),
      unitConvertion: productlistUnits,
      costPrice: data?.costPrice,
      ProductLength:data?.ProductLength,
      ProductGrade:data?.ProductGrade,
      ProductDrawing:data?.ProductDrawing,
      ProductReference:data?.ProductReference,
      ProductDeliveryStation:data?.ProductDeliveryStation
    }
    setLoading(true)
    try {
      const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/Product/UpdateProduct`, upload);
      if (response.data.status == 1) {
        toast.success(response.data.message);
        navigate('/product-list')
        setLoading(false)
      }

    } catch (error) {
      setLoading(false)
      console.log(error);
    }
  }
  const handleSelectDepartment = (selectedOption) => {
    setSelectedDepartment(selectedOption);
  };
  const handleSelectunitlist = (selectedOption) => {
    setSelectedunitlist(selectedOption);
  };
  const handleSelectCategory = (selectedOption) => {
    setSelectedCategory(selectedOption);
  };
  const handleSelectgender = (selectedOption) => {
    setSelectedStatus(selectedOption)
  }
  const handleUnitProduct = (selectedOption) => {
    setSelectunitPro(selectedOption)
  }
  const handleUnitUnit = (selectedOption) => {
    setSelectunitU(selectedOption)
  }
  const handleAddProduct = (e) => {
    if (selectunitPro) {
      setAddProductList([...productlistUnits, {
        productname: selectunitPro?.label,
        productid: selectunitPro?.value,
        altunitname: selectunitU?.label,
        altunitid: selectunitU?.value,
        conversionfactor: selectunitqty,
      }]);
      // Reset the input values
      setSelectunitPro(null);
      setSelectunitU(null);
      setSelectunitQty('');
    }
  }
  const deletefunction = (id) => {
    let indexToDelete = id;

    if (indexToDelete >= 0 && indexToDelete < productlistUnits.length) {
      // Create a new array without the element at the specified index
      const updatedProductList = [...productlistUnits.slice(0, indexToDelete), ...productlistUnits.slice(indexToDelete + 1)];

      // Update the state with the new array
      setAddProductList(updatedProductList);
    } else {
      console.log("Index out of range");
    }
  };
  return (
    <>
      <Fragment>
        <Fragment>
          <div className='page-header'>
            <div className="page-leftheader">
              <h4 className="page-title mb-0">Edit Product</h4>
            </div>
            <div className="page-rightheader">
              <div className='d-flex align-items-center'>

              </div>
            </div>
          </div>
        </Fragment>
        <Row>
          <Col xl={12} md={12} sm={12}>
            <Card>
              <Card.Body>
                <Form onSubmit={handleSubmit(onSubmit)}>
                  <Row>
                    <Form.Group as={Col} md="4" controlId="validationCustom01">
                      <Form.Control
                        type="text"
                        name='productCode'
                        placeholder="Code"
                        {...register("productCode")}
                      />
                      <p className="errorMsg pl-3 mt-2">{errors.productCode ? errors.productCode.message : ''}</p>
                    </Form.Group>
                    <Form.Group as={Col} md="4" controlId="validationCustom01">
                      <Form.Control
                        type="text"
                        name='productName'
                        placeholder="Name"
                        {...register("productName")}
                      />
                      <p className="errorMsg pl-3 mt-2">{errors.productName ? errors.productName.message : ''}</p>
                    </Form.Group>
                    <Form.Group as={Col} md="4" controlId="validationCustom01">
                      <Form.Control
                        type="text"
                        name='productDescription'
                        placeholder="Description"
                        {...register("productDescription")}
                      />
                      <p className="errorMsg pl-3 mt-2">{errors.productDescription ? errors.productDescription.message : ''}</p>
                    </Form.Group>
                  </Row>
                  <Row>
                    <Form.Group as={Col} md="4" controlId="validationCustom01">
                      <Select
                        value={selectedunitlist}
                        onChange={handleSelectunitlist}
                        options={unitlist}
                        isSearchable
                        placeholder="Search Units"
                      />
                      <p className="errorMsg pl-3 mt-2">{msg2}</p>
                    </Form.Group>
                    <Form.Group as={Col} md="4" controlId="validationCustom01">
                      <Form.Control
                        type="text"
                        name='costPrice'
                        placeholder="Price"
                        {...register("costPrice")}
                      />
                      <p className="errorMsg pl-3 mt-2">{errors.costPrice ? errors.costPrice.message : ''}</p>
                    </Form.Group>
                    <Form.Group as={Col} md="4" controlId="validationCustom01">
                      <Select
                        value={selecteddepartment}
                        onChange={handleSelectDepartment}
                        options={department}
                        isSearchable
                        placeholder="Search Brands"
                      />
                      <p className="errorMsg pl-3 mt-2">{msg1}</p>
                    </Form.Group>
                  </Row>
                  <Row>
                    <Form.Group as={Col} md="4" controlId="validationCustom01">
                      <Select
                        value={selectedcategory}
                        onChange={handleSelectCategory}
                        options={categorylist}
                        isSearchable
                        placeholder="Search Category"
                      />
                      <p className="errorMsg pl-3 mt-2">{msg2}</p>
                    </Form.Group>
                    <Form.Group as={Col} md="4" controlId="validationCustom01">
                      <Form.Control
                        type="text"
                        name='productColour'
                        placeholder="Color"
                        {...register("productColour")}
                      />
                      <p className="errorMsg pl-3 mt-2">{errors.productColour ? errors.productColour.message : ''}</p>
                    </Form.Group>
                    <Form.Group as={Col} md="4" controlId="validationCustom01">
                      <Form.Control
                        type="text"
                        name='ProductLength'
                        placeholder="Length"
                        {...register("ProductLength")}
                      />
                      <p className="errorMsg pl-3 mt-2">{errors.ProductLength ? errors.ProductLength.message : ''}</p>
                    </Form.Group>
                  </Row>
                  <Row>
                    <Form.Group as={Col} md="4" controlId="validationCustom01">
                      <Form.Control
                        type="text"
                        name='ProductGrade'
                        placeholder="Grade"
                        {...register("ProductGrade")}
                      />
                      <p className="errorMsg pl-3 mt-2">{errors.ProductGrade ? errors.ProductGrade.message : ''}</p>
                    </Form.Group>
                    <Form.Group as={Col} md="4" controlId="validationCustom01">
                      <Form.Control
                        type="text"
                        name='ProductDrawing'
                        placeholder="Drawing"
                        {...register("ProductDrawing")}
                      />
                      <p className="errorMsg pl-3 mt-2">{errors.ProductDrawing ? errors.ProductDrawing.message : ''}</p>
                    </Form.Group>
                    <Form.Group as={Col} md="4" controlId="validationCustom01">
                      <Form.Control
                        type="text"
                        name='ProductReference'
                        placeholder="Reference"
                        {...register("ProductReference")}
                      />
                      <p className="errorMsg pl-3 mt-2">{errors.ProductReference ? errors.ProductReference.message : ''}</p>
                    </Form.Group>

                  </Row>
                  <Row>
                    <Form.Group as={Col} md="4" controlId="validationCustom01">
                      <Form.Control
                        type="text"
                        name='ProductDeliveryStation'
                        placeholder="DeliveryStation"
                        {...register("ProductDeliveryStation")}
                      />
                      <p className="errorMsg pl-3 mt-2">{errors.ProductDeliveryStation ? errors.ProductDeliveryStation.message : ''}</p>
                    </Form.Group>
                    <Form.Group as={Col} md="4" controlId="validationCustom01">
                      <Form.Control
                        type="file"
                        name='productImage'
                        placeholder="Color"
                        {...register("productImage")}
                      />
                      <p className="errorMsg pl-3 mt-2">{errors.productImage ? errors.productImage.message : ''}</p>
                    </Form.Group>
                    <Form.Group as={Col} md="4" controlId="validationCustom01">
                      <Select
                        value={selectedstatus}
                        onChange={handleSelectgender}
                        options={[{
                          label: 'Active',
                          value: 0
                        }, {
                          label: 'InActive',
                          value: 1
                        }]}
                        isSearchable={false}
                        placeholder="Status"
                      />
                      <p className="errorMsg pl-3 mt-2">{msg5}</p>
                    </Form.Group>
                  </Row>
                  <div className="row card custom-card">
                    <div className="table-responsive col-12">
                      <table className="gridjs-table mt-3">
                        <thead>
                          <tr>
                            <th className='gridjs-th'>Unit</th>
                            <th className='gridjs-th'>Conversion Factor</th>
                            <th className='gridjs-th'>Price</th>
                            <th className='gridjs-th'>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {productlistUnits?.map((contact, index) => {
                            return (
                              <tr key={index}>
                                <td className="gridjs-td">{contact.altunitname ? contact.altunitname : contact.unitName}</td>
                                <td className="gridjs-td">{contact.conversionfactor ? contact.conversionfactor : contact.conversionFactor}</td>
                                <td className="gridjs-td">{contact.costPrice}</td>
                                <td className="gridjs-td">
                                  <div className="hstack gap-2 fs-15">
                                    <a className="btn btn-icon btn-sm btn-light" style={{ marginLeft: 10, marginRight: 10 }} onClick={() => deletefunction(index)}><img src={del} /></a>
                                  </div>
                                </td>
                              </tr>
                            )
                          })}

                        </tbody>
                      </table>
                      <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 15, marginBottom: 15 }}>
                        <img src={plus} style={{ width: 50, height: 50 }} data-bs-toggle="modal" data-bs-target="#exampleModal" />
                      </div>
                    </div>
                  </div>
                  <div className="d-flex justify-content-center formfilter mt-5">
                    <Button type="submit" className="mx-2">{loading ?
                      <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "25px" }}>
                        <Oval

                          heigth="20"
                          width="20"
                          color='#12C412'
                          arialLabel='loading'
                        />
                      </div> : "Submit"}</Button>
                  </div>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>

      </Fragment>
      <div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">Add Product</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <div className="row mt-1">
                <div className="col-12">
                  <label className='mt-1'>product</label>
                  <Select
                    value={selectunitPro}
                    onChange={handleUnitProduct}
                    options={prolist}
                    isSearchable
                    placeholder="Search Units"
                  />
                </div>
                <div className="col-12">
                  <label className='mt-1'>Unit</label>
                  <Select
                    value={selectunitU}
                    onChange={handleUnitUnit}
                    options={unitlist}
                    isSearchable
                    placeholder="Search Units"
                  />
                </div>
                <div className="col-12">
                  <label className='mt-1'>Conversion factor</label>
                  <Form.Control
                    type="text"
                    name='conversionfactor'
                    value={selectunitqty}
                    placeholder="conversion factor"
                    onChange={(e) => setSelectunitQty(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-primary" data-bs-dismiss="modal" aria-label="Close" onClick={handleAddProduct}>Add</button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default EditProduct
