import React, { FC, Fragment, useState, useEffect } from 'react';
import { Button, Card, Col, InputGroup, Row, Form, Alert, Nav, Tab } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useLocation, useSearchParams } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import PageHeader from '../../../layouts/pageheader';
import { Oval } from "react-loader-spinner";
import Select from 'react-select';

function AddSupplierContact() {
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedCat, setSelectedCat] = useState([])
  const [data, setData] = useState([])
  const [selecteddata, setSelectedData] = useState([])
  const schema = yup.object().shape({
  })
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  useEffect(()=>{
    supplierlist()
  },[])
  const supplierlist = async (value) => {
    setLoading(true)
    axios({
      url: `${process.env.REACT_APP_SERVER_URL}/api/Supplier/GetSupplierList?supName=`,
      method: 'GET',
    }).then((response) => {
      setLoading(false)
      const formattedData = response.data.data.map(item => ({
        label: item.supName,
        value: item.supplierId
      }));
      setData(formattedData)
    })
      .catch((error) => {
        setLoading(false)
      })
  };
  const onSubmit = async (data) => {
    const upload = {
      userId: "string",
      contactName: data?.contactName,
      contactEmail:  data?.contactEmail,
      contactPhoneNumber:  data?.contactPhoneNumber,
      contactIsPrimary: selectedCat?.value,
      supplierContactId: 0,
      supplierId: selecteddata?.value
    }
    setLoading(true)
    try {
      const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/SupplierContact/CreateSupplierContact`, upload);
      if (response.data.status == 1) {
        toast.success(response.data.message);
        navigate('/supplier-contact-list')
        setLoading(false)
      }
    } catch (error) {
      setLoading(false)
      console.log(error);
    }
  }
  const [img, setImg] = useState()
  const handleImageChange = (e) => {
    setImg(e.target.files[0])
  };
  const handleSelectCat = (selectedOption) => {
    setSelectedCat(selectedOption)
  }
  const handleSelectData = (selectedOption) => {
    setSelectedData(selectedOption)
  }
  return (
    <Fragment>
      <Fragment>
        <div className='page-header'>
          <div className="page-leftheader">
            <h4 className="page-title mb-0">Add Supplier contact</h4>
          </div>
          <div className="page-rightheader">
            <div className='d-flex align-items-center'>

            </div>
          </div>
        </div>
      </Fragment>
      <Row>
        <Col xl={4} md={12} sm={12}>
        </Col>
        <Col xl={4} md={12} sm={12}>
          <Card>
            <Card.Body>
              <Form onSubmit={handleSubmit(onSubmit)}>
              <Form.Group as={Col} md="12" controlId="validationCustom01">
                  <Select
                    value={selectedCat}
                    onChange={handleSelectData}
                    options={data}
                    isSearchable
                    required
                    placeholder="Select Supplier"
                  />
                  <p className="errorMsg pl-3 mt-2">{errors.supEmail ? errors.supEmail.message : ''}</p>
                </Form.Group>
                <Form.Group as={Col} md="12" controlId="validationCustom01">
                  <Form.Control
                    type="text"
                    name='contactName'
                    placeholder="Name"
                    {...register("contactName")}
                  />
                  <p className="errorMsg pl-3 mt-2">{errors.contactName ? errors.contactName.message : ''}</p>
                </Form.Group>
                <Form.Group as={Col} md="12" controlId="validationCustom01">
                  <Form.Control
                    type="text"
                    name='contactEmail'
                    placeholder="Email"
                    {...register("contactEmail")}
                  />
                  <p className="errorMsg pl-3 mt-2">{errors.contactEmail ? errors.contactEmail.message : ''}</p>
                </Form.Group>
                <Form.Group as={Col} md="12" controlId="validationCustom01">
                  <Form.Control
                    type="text"
                    name='contactPhoneNumber'
                    placeholder="Phone Number"
                    {...register("contactPhoneNumber")}
                  />
                  <p className="errorMsg pl-3 mt-2">{errors.contactPhoneNumber ? errors.contactPhoneNumber.message : ''}</p>
                </Form.Group>
                <Form.Group as={Col} md="12" controlId="validationCustom01">
                  <Select
                    value={selectedCat}
                    onChange={handleSelectCat}
                    options={[{
                      label:'true',
                      value:true
                    },
                    {
                      label:'false',
                      value:false
                    }
                  ]}
                    isSearchable={false}
                    required
                    placeholder="Is Primary Contact?"
                  />
                  <p className="errorMsg pl-3 mt-2">{errors.supEmail ? errors.supEmail.message : ''}</p>
                </Form.Group>
                <div className="d-flex justify-content-center formfilter mt-5">
                  <Button type="submit" className="mx-2">{loading ?
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "25px" }}>
                      <Oval

                        heigth="20"
                        width="20"
                        color='#12C412'
                        arialLabel='loading'
                      />
                    </div> : "Submit"}</Button>
                </div>
              </Form>
            </Card.Body>
          </Card>
        </Col>
        <Col xl={4} md={12} sm={12}>
        </Col>
      </Row>

    </Fragment>
  )
}

export default AddSupplierContact
