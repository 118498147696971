import React, { FC, Fragment, useState, useEffect } from 'react';
import { Button, Card, Col, InputGroup, Row, Form, Alert, Nav, Tab } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useLocation, useSearchParams } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import PageHeader from '../../../layouts/pageheader';
import { Oval } from "react-loader-spinner";
import Select from 'react-select';

function EditSupplierContact() {
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedCat, setSelectedCat] = useState([])
  const [data, setData] = useState([])
  const [selecteddata, setSelectedData] = useState([])
  const schema = yup.object().shape({
  })
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await axios.get(
          `${process.env.REACT_APP_SERVER_URL}/api/SupplierContact/GetSupplierContactById?SupplierContactId=${parseInt(location.state.id)}`
        );
        const companyData = response.data.data;
        setValue("contactName", companyData.contactName);
        setValue("contactEmail", companyData.contactEmail);
        setValue("contactPhoneNumber", companyData.contactPhoneNumber);
        setSelectedCat({
          label: companyData.contactIsPrimary === true ? 'true' : 'false',
          value: companyData.contactIsPrimary
        })
        setSelectedData({
          label: companyData.supName,
          value: companyData.supplierId
        })
      } catch (error) {
        console.error("Error fetching company data:", error);
        setLoading(false);
        // Handle the error appropriately (e.g., show an error message to the user)
      }
    };
    fetchData();
  }, [location.state.id, setValue, setLoading]);
  const onSubmit = async (data) => {
    const upload = {
      userId: "string",
      contactName: data?.contactName,
      contactEmail: data?.contactEmail,
      contactPhoneNumber: data?.contactPhoneNumber,
      contactIsPrimary: selectedCat?.label === 'true' ? true : false,
      supplierContactId: selectedCat?.value,
      supplierId: selecteddata?.value,
      supplierContactId: parseInt(location.state.id)
    }
    setLoading(true)
    try {
      const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/SupplierContact/UpdateSupplierContact`, upload);
      if (response.data.status == 1) {
        toast.success(response.data.message);
        navigate('/supplier-list')
        setLoading(false)
      }
    } catch (error) {
      setLoading(false)
      console.log(error);
    }
  }
  const [img, setImg] = useState()
  const handleImageChange = (e) => {
    setImg(e.target.files[0])
  };
  const handleSelectCat = (selectedOption) => {
    setSelectedCat(selectedOption)
  }
  const handleSelectData = (selectedOption) => {
    setSelectedData(selectedOption)
  }
  return (
    <Fragment>
      <Fragment>
        <div className='page-header'>
          <div className="page-leftheader">
            <h4 className="page-title mb-0">Edit Supplier contact</h4>
          </div>
          <div className="page-rightheader">
            <div className='d-flex align-items-center'>

            </div>
          </div>
        </div>
      </Fragment>
      <Row>
        <Col xl={4} md={12} sm={12}>
        </Col>
        <Col xl={4} md={12} sm={12}>
          <Card>
            <Card.Body>
              <Form onSubmit={handleSubmit(onSubmit)}>
                <Form.Group as={Col} md="12" controlId="validationCustom01">
                  <Select
                    value={selectedCat}
                    onChange={handleSelectData}
                    options={data}
                    isSearchable
                    required
                    placeholder="Select Supplier"
                  />
                  <p className="errorMsg pl-3 mt-2">{errors.supEmail ? errors.supEmail.message : ''}</p>
                </Form.Group>
                <Form.Group as={Col} md="12" controlId="validationCustom01">
                  <Form.Control
                    type="text"
                    name='contactName'
                    placeholder="Name"
                    {...register("contactName")}
                  />
                  <p className="errorMsg pl-3 mt-2">{errors.contactName ? errors.contactName.message : ''}</p>
                </Form.Group>
                <Form.Group as={Col} md="12" controlId="validationCustom01">
                  <Form.Control
                    type="text"
                    name='contactEmail'
                    placeholder="Email"
                    {...register("contactEmail")}
                  />
                  <p className="errorMsg pl-3 mt-2">{errors.contactEmail ? errors.contactEmail.message : ''}</p>
                </Form.Group>
                <Form.Group as={Col} md="12" controlId="validationCustom01">
                  <Form.Control
                    type="text"
                    name='contactPhoneNumber'
                    placeholder="Phone Number"
                    {...register("contactPhoneNumber")}
                  />
                  <p className="errorMsg pl-3 mt-2">{errors.contactPhoneNumber ? errors.contactPhoneNumber.message : ''}</p>
                </Form.Group>
                <Form.Group as={Col} md="12" controlId="validationCustom01">
                  <Select
                    value={selectedCat}
                    onChange={handleSelectCat}
                    options={[{
                      label: 'true',
                      value: 1
                    },
                    {
                      label: 'false',
                      value: 0
                    }
                    ]}
                    isSearchable={false}
                    required
                    placeholder="Is Primary Contact?"
                  />
                  <p className="errorMsg pl-3 mt-2">{errors.supEmail ? errors.supEmail.message : ''}</p>
                </Form.Group>
                <div className="d-flex justify-content-center formfilter mt-5">
                  <Button type="submit" className="mx-2">{loading ?
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "25px" }}>
                      <Oval

                        heigth="20"
                        width="20"
                        color='#12C412'
                        arialLabel='loading'
                      />
                    </div> : "Submit"}</Button>
                </div>
              </Form>
            </Card.Body>
          </Card>
        </Col>
        <Col xl={4} md={12} sm={12}>
        </Col>
      </Row>

    </Fragment>
  )
}

export default EditSupplierContact
