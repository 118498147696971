import React, { FC, Fragment, useState, useEffect } from 'react';
import { Button, Card, Col, InputGroup, Row, Form, Alert, Nav, Tab } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useLocation, useSearchParams } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import PageHeader from '../../../layouts/pageheader';
import { Oval } from "react-loader-spinner";
import Select from 'react-select';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function EditEmploye() {
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate();
    const location = useLocation();
    const schema = yup.object().shape({
    })
    const {
        register,
        handleSubmit,
        reset,
        setValue,
        clearErrors,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    });
    useEffect(() => {
        designations()
        Departments()
        Employeeviewbyid()
    }, [])
    const [startDate, setStartDate] = useState();
    const [startDate1, setStartDate1] = useState();
    const [department, setDepartment] = useState([])
    const [designation, setDesignation] = useState([])
    const [selecteddepartment, setSelectedDepartment] = useState([])
    const [selectedgender, setSelectedgender] = useState([])
    const [selecteddesignation, setSelectedDesignation] = useState([])
    const Employeeviewbyid=()=>{
        axios({
            url: `${process.env.REACT_APP_SERVER_URL}/api/Employee/GetEmployeeById?EmpId=${parseInt(location.state.id)}`,
            method: 'GET',
        }).then((response) => {
            setLoading(false)
            setValue("empName", response.data.data.employeeName)
            setValue("empCode", response.data.data.employeeCode)
            setValue("empAddress", response.data.data.address)
            setValue("empPhoneNumber", response.data.data.phone)
            setValue("empEmail", response.data.data.email)
            setValue("empJobTitle", response.data.data.jobTitle)
            setStartDate(new Date(response.data.data.dob))
            setStartDate1(new Date(response.data.data.doj))
            setSelectedDepartment([{
                label:response.data.data.departmentName,
                value:response.data.data.departmentId
            }])
            setSelectedDesignation([{
                label:response.data.data.designationName,
                value:response.data.data.designationId
            }])
            setSelectedgender([{
                label:response.data.data.gender==='0'?'Male':'Female',
                value:response.data.data.gender
            }])
        })
            .catch((error) => {
                setLoading(false)
            })
    }
    const designations = async (value) => {
        axios({
            url: `${process.env.REACT_APP_SERVER_URL}/api/Designation/GetDesignationList`,
            method: 'GET',
        }).then((response) => {
            const formattedData = response.data.data.map(item => ({
                label: item.designationName,
                value: item.designationId
            }));
            setDesignation(formattedData)
        })
            .catch((error) => {
            })
    };
    const Departments = async (value) => {
        axios({
            url: `${process.env.REACT_APP_SERVER_URL}/api/Department/GetDepartmentList`,
            method: 'GET',
        }).then((response) => {
            const formattedData = response.data.data.map(item => ({
                label: item.departmentName,
                value: item.departmentId
            }));
            setDepartment(formattedData)
        })
            .catch((error) => {
            })
    };
    const onSubmit = async (data) => {
        const upload = {
            userId: "string",
            empName: data?.empName,
            empCode: data?.empName,
            empDepartmentId: selecteddepartment?.value,
            empDesignationId: selecteddesignation?.value,
            empId: parseInt(location.state.id),
            empAddress: data?.empName,
            empDOB: new Date(startDate),
            empPhoneNumber:data?.empName,
            empGender: selectedgender?.value,
            empDOJ: new Date(startDate1),
            empEmail: data?.empName,
            empJobTitle: data?.empName,
        }
        setLoading(true)
        try {
            const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/Employee/UpdateEmployee`, upload);
            if (response.data.status == 1) {
                toast.success(response.data.message);
                navigate('/employe-list')
                setLoading(false)
            }

        } catch (error) {
            setLoading(false)
            console.log(error);
        }
    }
    const handleSelectDepartment = (selectedOption) => {
        setSelectedDepartment(selectedOption);
    };
    const handleSelectDesignation = (selectedOption) => {
        setSelectedDesignation(selectedOption);
    };
    const handleSelectGender = (selectedOption) => {
        setSelectedgender(selectedOption);
    };
    return (
        <Fragment>
            <Fragment>
                <div className='page-header'>
                    <div className="page-leftheader">
                        <h4 className="page-title mb-0">Edit Employee</h4>
                    </div>
                    <div className="page-rightheader">
                        <div className='d-flex align-items-center'>

                        </div>
                    </div>
                </div>
            </Fragment>
            <Row>
                <Col xl={4} md={12} sm={12}>
                </Col>
                <Col xl={4} md={12} sm={12}>
                    <Card>
                        <Card.Body>
                            <Form onSubmit={handleSubmit(onSubmit)}>
                                <Form.Group as={Col} md="12" controlId="validationCustom01">
                                    <Form.Control
                                        type="text"
                                        name='empName'
                                        placeholder="Employee Name"
                                        {...register("empName")}
                                    />
                                    <p className="errorMsg pl-3 mt-2">{errors.empName ? errors.empName.message : ''}</p>
                                </Form.Group>
                                <Form.Group as={Col} md="12" controlId="validationCustom01">
                                    <Form.Control
                                        type="text"
                                        name='empCode'
                                        placeholder="Employee code"
                                        {...register("empCode")}
                                    />
                                    <p className="errorMsg pl-3 mt-2">{errors.empCode ? errors.empCode.message : ''}</p>
                                </Form.Group>
                                <Form.Group as={Col} md="12" controlId="validationCustom01">
                                    <Select
                                        value={selecteddepartment}
                                        onChange={handleSelectDepartment}
                                        options={department}
                                        isSearchable
                                        placeholder="Search Department"
                                    />
                                    <p className="errorMsg pl-3 mt-2"></p>
                                </Form.Group>
                                <Form.Group as={Col} md="12" controlId="validationCustom01">
                                    <Select
                                        value={selecteddesignation}
                                        onChange={handleSelectDesignation}
                                        options={designation}
                                        isSearchable
                                        placeholder="Search Designation"
                                    />
                                    <p className="errorMsg pl-3 mt-2"></p>
                                </Form.Group>
                                <Form.Group as={Col} md="12" controlId="validationCustom01">
                                    <Form.Control
                                        type="text"
                                        name='empAddress'
                                        placeholder="Address"
                                        {...register("empAddress")}
                                    />
                                    <p className="errorMsg pl-3 mt-2">{errors.empAddress ? errors.empAddress.message : ''}</p>
                                </Form.Group>
                                <Form.Group as={Col} md="12" controlId="validationCustom01">
                                    <Select
                                        value={selectedgender}
                                        onChange={handleSelectGender}
                                        options={[{
                                            label:'Male',
                                            value:0
                                        },
                                        {
                                            label:'Female',
                                            value:1
                                        }]}
                                        isSearchable
                                        placeholder="Select Gender"
                                    />
                                    <p className="errorMsg pl-3 mt-2"></p>
                                </Form.Group>
                                <Form.Group as={Col} md="12" controlId="validationCustom01">
                                    <div style={{ border: '1px solid #f3f3f3', width: '100%', padding: 8 }}>
                                        <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} placeholderText='Date of Birth' />
                                    </div>
                                    <p className="errorMsg pl-3 mt-2"></p>
                                </Form.Group>
                                <Form.Group as={Col} md="12" controlId="validationCustom01">
                                    <Form.Control
                                        type="text"
                                        name='empPhoneNumber'
                                        placeholder="Phone Number"
                                        {...register("empPhoneNumber")}
                                    />
                                    <p className="errorMsg pl-3 mt-2">{errors.empPhoneNumber ? errors.empPhoneNumber.message : ''}</p>
                                </Form.Group>
                                <Form.Group as={Col} md="12" controlId="validationCustom01">
                                    <div style={{ border: '1px solid #f3f3f3', width: '100%', padding: 8 }}>
                                        <DatePicker selected={startDate1} onChange={(date) => setStartDate1(date)} placeholderText='Date of Joining' />
                                    </div>
                                    <p className="errorMsg pl-3 mt-2"></p>
                                </Form.Group>
                                <Form.Group as={Col} md="12" controlId="validationCustom01">
                                    <Form.Control
                                        type="text"
                                        name='empEmail'
                                        placeholder="Email"
                                        {...register("empEmail")}
                                    />
                                    <p className="errorMsg pl-3 mt-2">{errors.empEmail ? errors.empEmail.message : ''}</p>
                                </Form.Group>
                                <Form.Group as={Col} md="12" controlId="validationCustom01">
                                    <Form.Control
                                        type="text"
                                        name='empJobTitle'
                                        placeholder="Job"
                                        {...register("empJobTitle")}
                                    />
                                    <p className="errorMsg pl-3 mt-2">{errors.empJobTitle ? errors.empJobTitle.message : ''}</p>
                                </Form.Group>
                                <div className="d-flex justify-content-center formfilter mt-5">
                                    <Button type="submit" className="mx-2">{loading ?
                                        <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "25px" }}>
                                            <Oval

                                                heigth="20"
                                                width="20"
                                                color='#12C412'
                                                arialLabel='loading'
                                            />
                                        </div> : "Submit"}</Button>
                                </div>
                            </Form>
                        </Card.Body>
                    </Card>
                </Col>
                <Col xl={4} md={12} sm={12}>
                </Col>
            </Row>

        </Fragment>
    )
}

export default EditEmploye
    
