import React, { FC, Fragment, useEffect, useState } from "react";
import { ImagesData } from "../../commoncomponents/images/commonimages";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import { Chart as ChartJS, defaults } from "chart.js/auto";
import { Bar, Doughnut, Line } from "react-chartjs-2";
import revenueData from "./dashboarddata/revenueData.json";
import { faFileInvoice } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import {
  ApexChartscontent,
  ResponsiveDataTable,
} from "./dashboarddata/boarddata";
import {
  Button,
  Card,
  Col,
  Pagination,
  ProgressBar,
  Row,
  Table,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import {
  Spark1,
  Spark2,
  Spark3,
  Spark4,
  products,
  products1,
  transactions,
  revenues,
} from "../dashboard/dashboarddata/boarddata";
import PageHeader from "../../layouts/pageheader";
import axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Piegraphs from "./dashboarddata/Piegraphs";
import Doughnutgraphs from "./dashboarddata/Doughnutgraphs";
import BarChart from "./dashboarddata/BarChart";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
export default function Main_dashboard() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [filter, setFilter] = useState({ location: "" });
  const [endDate, setEndDate] = useState(new Date());
  const [locationSearch, setLocationSearch] = useState([]);
  const [liveDatas, setLiveDatas] = useState([]);
  const [hourData, sethourData] = useState([]);
  const [CountyList, setCountyList] = useState([]);
  const [totalRecords, setTotalRecords] = useState("");
  const changeHandler = (name) => async (event) => {
    setFilter({ ...filter, [name]: event.target.value });
  };
  useEffect(() => {
    const token = localStorage.getItem("accessToken");
    if (!token) {
      navigate("/");
    }

    dashboardCountdata();
    liveData();
  }, [filter.location, startDate, endDate]);
  const handleReset = () => {
    setStartDate(null);
    setEndDate(null);
    setFilter({ location: "" });
  };
  const dashboardCountdata = async () => {
    setLoading(true);
    let dateStart = "";
    let dateEnd = "";

    if (startDate) {
      dateStart = moment(startDate).format("YYYY-MM-DD");
    }
    if (endDate) {
      dateEnd = moment(startDate).format("YYYY-MM-DD");
    }
    await axios({
      url: `${
        process.env.REACT_APP_SERVER_URL
      }/api/Trip/GetDashboardList`,
      method: "GET",
    })
      .then((response) => {
        console.log(response?.data?.data);
        if (response.data.status === 1) {
          setData(response?.data?.data);
          setLocationSearch(response?.data?.data?.locationWiseSales);
          setTotalRecords(response?.data?.data?.locationWiseSales?.length);
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };
 
  const liveData = async () => {
    setLoading(true);
    await axios({
      url: `${process.env.REACT_APP_SERVER_URL}/api/Trip/LiveData`,
      method: "GET",
    })
      .then((response) => {
        console.log(response?.data?.data);
        if (response.data.status === 1) {
          setLiveDatas(response?.data?.data);
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  
  return (
    <Fragment>
      <div className="page-header">
        <h4 className="page-title mb-0">Dashboard</h4>
      </div>
      <div class="container mb-5" style={{ marginLeft: "-10px" }}>
        <Row>
          <Col
            xxl={8}
            lg={8}
            md={6}
            className="xm-12 d-flex justify-content-start align-items-center gap-2"
          >
         

           
         
          </Col>
          <Col
            xxl={4}
            lg={4}
            md={6}
            className="xm-12 d-flex justify-content-center align-items-center px-2 gap-2"
          ></Col>
        </Row>
      </div>
      <Row>
        <Col xxl={2} lg={4} md={4} className="xm-12" id="salescard">
          <Card className="overflow-hidden dash1-card border-0 dash1">
            <Card.Body>
              <Row>
                <Col md={12} sm={12} className="col-12">
                  <div>
                    <span className="fs-14">Total Trip Amount</span>
                    <h2 className="mb-2 mt-1 number-font carn2 font-weight-bold">
                      {data?.sales ?? "0"}
                    </h2>
                  </div>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
        <Col xxl={2} lg={4} md={4} className="xm-12">
          <Card className="overflow-hidden dash1-card border-0 dash2">
            <Card.Body>
              <Row>
                <Col md={12} sm={12} className="col-6">
                  <div>
                    <span className="fs-14">Total Trips</span>
                    <h2 className="mb-2 mt-1 number-font carn2 font-weight-bold">
                      {data?.orders ?? "0"}
                    </h2>
                  </div>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
        
       
      </Row>

      <Row className="row">
        <Col xl={8} lg={12} md={12} sm={12}>
          <Card>
            <Card.Header className="border-bottom-0">
              <Card.Title>Sales</Card.Title>
            </Card.Header>
            <Card.Body className="pt-0">
              <div className="chart-wrapper">
                <div id="spark1">
                  <Line
                    data={{
                      labels: data?.locationWiseSales?.map(
                        (data) => data.location
                      ),
                      datasets: [
                        {
                          label: "Sales",
                          data: data?.locationWiseSales?.map(
                            (data) => data.amount
                          ),
                          backgroundColor: data?.locationWiseSales?.map(
                            (data) => data.color
                          ),
                          borderColor: data?.locationWiseSales?.map(
                            (data) => data.color
                          ),
                        },
                        {
                          label: "Order",
                          data: data?.locationOrders?.map(
                            (data) => data.orders
                          ),
                          backgroundColor: data?.locationOrders?.map(
                            (data) => data.color
                          ),
                          borderColor: data?.locationOrders?.map(
                            (data) => data.color
                          ),
                        },
                      ],
                    }}
                    options={{
                      elements: {
                        line: {
                          tension: 0.5,
                        },
                      },
                      plugins: {
                        title: {
                          text: "Sales & Oders",
                        },
                      },
                    }}
                  />
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col xl={4} lg={12} md={12} sm={12} id="transactions">
          <Card>
            <Card.Header>
              <Card.Title>Recent Trips</Card.Title>
            </Card.Header>
            <Card.Body className="p-0">
              <div>
                <div className="recentscroll">
                  {liveDatas?.map((product, index) => (
                    <ul className="recent-activity" key={Math.random()}>
                      <li className="mb-5 mt-5">
                        <div>
                          <span
                            className={`activity-timeline text-white`}
                            style={{ backgroundColor: product.color }}
                          >
                            {product.locCode}
                          </span>
                          <div className="activity-timeline-content">
                            <span className="font-weight-normal1 fs-13">
                              {product.locName} placed a Trip{" "}
                              {product.amount}
                            </span>
                            <span className="text-muted fs-12 float-end">
                              {product.invTime}
                            </span>
                            <span
                              className={`activity-sub-content text-${product.color1} fs-11`}
                            >
                              {product.orderType}
                            </span>
                            <p className="text-muted fs-12 mt-1">
                              {moment(product.invDate).format("DD/MM/YYYY")}
                            </p>
                          </div>
                        </div>
                      </li>
                    </ul>
                  ))}
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        {/* <Col md={12} sm={12} lg={12} xl={6}>
          <Card>
            <Card.Header>
              <Card.Title>Sales by Location</Card.Title>
            </Card.Header>
            <Card.Body>
              <div id="spark2">
                <Piegraphs data={data} />
              </div>
            </Card.Body>
          </Card>
        </Col> */}

        {/* <Col md={12} sm={12} lg={6} xl={3}>
          <Card>
            <Card.Header>
              <Card.Title>Locaton Wise Sales</Card.Title>
            </Card.Header>
            <Card.Body className="p-0 py-2">
              <div className="table-responsive">
                <Table className="table table-hover card-table table-vcenter text-nowrap">
                  <thead className="border-bottom-0 pt-3 pb-3">
                    <tr>
                      <th
                        className="text-left"
                        style={{ fontWeight: "800", color: "#7373dd" }}
                      >
                        SI.No
                      </th>
                      <th
                        className="text-left"
                        style={{ fontWeight: "800", color: "#7373dd" }}
                      >
                        Location
                      </th>
                      <th
                        className="text-left"
                        style={{ fontWeight: "800", color: "#7373dd" }}
                      >
                        Quantity
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {locationSearch.map((Product1, index) => (
                      <tr key={Math.random()}>
                        <td className="text-left">#{index + 1}</td>
                        <td className="text-left">{Product1.location}</td>
                        <td
                          className="text-right"
                          style={{ textAlign: "right" }}
                        >
                          {Product1.amount}
                        </td>
                      </tr>
                    ))}
                    <tr>
                      <td className="text-left"></td>
                      <td
                        className="text-left pt-2 pb-2"
                        style={{ fontWeight: "700" }}
                      >
                        Total Amount
                      </td>
                      <td
                        className="text-left"
                        style={{ textAlign: "right", fontWeight: "700" }}
                      >
                        {data?.totalAmount}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </Card.Body>
          </Card>
        </Col>

        <Col md={12} sm={12} lg={6} xl={3}>
          <Card>
            <Card.Header>
              <Card.Title>Locaton Wise Order</Card.Title>
            </Card.Header>
            <Card.Body className="p-0 py-2">
              <div className="table-responsive">
                <Table className="table table-hover card-table table-vcenter text-nowrap">
                  <thead className="border-bottom-0 pt-3 pb-3">
                    <tr>
                      <th
                        className="text-left"
                        style={{ fontWeight: "800", color: "#7373dd" }}
                      >
                        SI.No
                      </th>
                      <th
                        className="text-left"
                        style={{ fontWeight: "800", color: "#7373dd" }}
                      >
                        Location
                      </th>
                      <th
                        className="text-left"
                        style={{ fontWeight: "800", color: "#7373dd" }}
                      >
                        Quantity
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {locationSearch.map((Product1, index) => (
                      <tr key={Math.random()}>
                        <td className="text-left">#{index + 1}</td>
                        <td className="text-left">{Product1.location}</td>
                        <td
                          className="text-right"
                          style={{ textAlign: "right" }}
                        >
                          {Product1.amount}
                        </td>
                      </tr>
                    ))}
                    <tr>
                      <td className="text-left"></td>
                      <td
                        className="text-left pt-2 pb-2"
                        style={{ fontWeight: "700" }}
                      >
                        Total Amount
                      </td>
                      <td
                        className="text-left"
                        style={{ textAlign: "right", fontWeight: "700" }}
                      >
                        {data?.totalAmount}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </Card.Body>
          </Card>
        </Col> */}
      </Row>
      {/* <Row style={{ marginTop: "-10px" }}>
        <Col md={12} sm={12} lg={12} xl={6}>
          <Card>
            <Card.Header>
              <Card.Title>Sales by Order</Card.Title>
            </Card.Header>
            <Card.Body>
              <div id="spark2">
                <Doughnutgraphs data={data} />
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col sm={12} md={12} lg={6} xl={6}>
          <Card>
            <Card.Header>
              <Card.Title>Hourly Breakdown Report</Card.Title>
            </Card.Header>
            <Card.Body>
              <div id="spark1">
                <BarChart data={data} />
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row> */}
      {/* <Row>
        <Col md={12} sm={12} lg={12} xl={8}>
          <Card>
            <Card.Header>
              <Card.Title>Top Products</Card.Title>
            </Card.Header>
            <Card.Body className=" p-0 py-2">
              <div>
                <div className="recentscroll1">
                  <div className="table-responsive">
                    <Table className="table table-hover card-table table-vcenter text-nowrap">
                      <thead className="border-bottom-0 pt-3 pb-3">
                        <tr>
                          <th
                            className="text-left"
                            style={{ fontWeight: "800", color: "#7373dd" }}
                          >
                            SI.No
                          </th>
                          <th
                            className="text-left"
                            style={{ fontWeight: "800", color: "#7373dd" }}
                          >
                            Product Name
                          </th>
                          <th
                            className="text-left"
                            style={{ fontWeight: "800", color: "#7373dd" }}
                          >
                            Location Name
                          </th>
                          <th
                            className="text-left"
                            style={{ fontWeight: "800", color: "#7373dd" }}
                          >
                            Net Amount
                          </th>
                          <th
                            className="text-left"
                            style={{ fontWeight: "800", color: "#7373dd" }}
                          >
                            Quantity
                          </th>
                        </tr>
                      </thead>
                      {data?.item?.length === 0 ? (
                        <tbody>
                          <tr key={Math.random()}>
                            <td></td>
                            <td></td>
                            <td>No Records</td>
                            <td></td>
                            <td></td>
                          </tr>
                        </tbody>
                      ) : (
                        <tbody>
                          {data?.item?.map((Product1, index) => (
                            <tr key={Math.random()}>
                              <td className="text-left">{index + 1}</td>
                              <td>{Product1.itemName}</td>
                              <td className={`fs-13 text-${Product1.color}`}>
                                {Product1.locName}
                              </td>
                              <td>
                                <span className="font-weight-normal1">
                                  {Product1.netAmount}
                                </span>
                              </td>
                              <td className="text-muted text-left">
                                {Product1.qty}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      )}
                    </Table>
                  </div>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col sm={12} md={12} lg={6} xl={4}>
          <Card>
            <Card.Header>
              <Card.Title>Top Grossing Group</Card.Title>
            </Card.Header>
            <Card.Body>
              <div>
                <div className="topgrossingscroll">
                  {data?.subGroup?.map((revenue) => (
                    <div className="mb-6" key={Math.random()}>
                      <div className="d-flex align-items-center justify-content-between">
                        <span className="text-muted fs-13 mb-1">
                          {revenue.locName}
                        </span>
                      </div>
                      <div className="d-flex justify-content-between mb-1">
                        <span className="fs-16 font-weight-normal1">
                          {revenue.subGroupName}
                        </span>
                        <span className="text-muted fs-12">
                          <i className="mdi mdi-arrow-up-thick text-success"></i>{" "}
                          {revenue.amount}
                        </span>
                      </div>
                      <ProgressBar
                        key={Math.random()}
                        className="progress-sm"
                        // variant={`${revenue.color}`}
                        striped variant="success"
                        animated
                        now={revenue.amount}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row> */}
    </Fragment>
  );
}
