import React, { FC, Fragment, useState, useEffect } from 'react';
import { Button, Card, Col, InputGroup, Row, Form, Alert, Nav, Tab } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useLocation, useSearchParams } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { Oval } from "react-loader-spinner";
import Select from 'react-select';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import edit from "../../assets/images/edit.svg";
import del from "../../assets/images/trash.svg";
import plus from "../../assets/images/plus.png";
import PerfectScrollbar from "react-perfect-scrollbar";

function AddSr() {
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate();
  const schema = yup.object().shape({
  })
  const {
    register,
    handleSubmit,
    reset,
    clearErrors,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  useEffect(() => {
    units()
    Polist()
    projectlist()
    //productslist()
  }, [])
  const [msg1, setMsg1] = useState();
  const [msg2, setMsg2] = useState();
  const [startDate, setStartDate] = useState(new Date());
  const [unitlist, setUnits] = useState([])
  const [prolist, setPro] = useState([])
  const [projectlists, setProjectList] = useState([])
  const [selectedsupplier, setSelectedSupplier] = useState([])
  const [productprice, setProductPrice] = useState([])
  const [selectedprojects, setSelectedProjects] = useState([])
  const [selectunitU, setSelectunitU] = useState([])
  const [supplierlist, setSupplierList] = useState([])
  const [selectunitPro, setSelectunitPro] = useState([])
  const [selectunitqty, setSelectunitQty] = useState('')
  const [productlistUnits, setAddProductList] = useState([])
  const [PONo, setPONo] = useState('')
  const units = async (value) => {
    axios({
      url: `${process.env.REACT_APP_SERVER_URL}/api/Unit/GetUnitList`,
      method: 'GET',
    }).then((response) => {
      const formattedData = response.data.data.map(item => ({
        label: item.unitCode,
        value: item.unitId
      }));
      setUnits(formattedData)
    })
      .catch((error) => {
      })
  };
  const productslist = async (value) => {
    axios({
      url: `${process.env.REACT_APP_SERVER_URL}/api/Product/GetPOProductList?PoId=${PONo?PONo:0}`,
      method: 'GET',
    }).then((response) => {
      setPro(response.data.data)
    })
      .catch((error) => {
      })
  };
  const Polist = async (value) => {
    axios({
      url: `${process.env.REACT_APP_SERVER_URL}/api/PO/GetPOList`,
      method: 'GET',
    }).then((response) => {
      const formattedData = response.data.data.map(item => ({
        label: item.poNumber,
        value: item.poId
      }));
      setSupplierList(formattedData)
    })
      .catch((error) => {
      })
  };
  const projectlist = async (value) => {
    axios({
      url: `${process.env.REACT_APP_SERVER_URL}/api/Project/GetProjectList`,
      method: 'GET',
    }).then((response) => {
      const formattedData = response.data.data.map(item => ({
        label: item.projectName,
        value: item.projectId
      }));
      setProjectList(formattedData)
    })
      .catch((error) => {
      })
  };
  const onSubmit = async (data) => {
    const upload = {
      userId: "string",
      poId: selectedsupplier.value,
      srvDate: startDate,
      srvNumber: data?.poNumber,
      currencyCode: data?.currencyCode,
      pO_details: productlistUnits
    }
    setLoading(true)
    try {
      const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/SRV/CreateSRV`, upload);
      if (response.data.status == 1) {
        toast.success(response.data.message);
        navigate('/PO-list')
        setLoading(false)
      }

    } catch (error) {
      setLoading(false)
      console.log(error);
    }
  }

  const handleSelectSupplier = (selectedOption) => {
    setSelectedSupplier(selectedOption);
    setPONo(selectedOption.value);
  };
  const handleSelectProject = (selectedOption) => {
    setSelectedProjects(selectedOption);
  };
  const handleUnitProduct = (selectedOption) => {
    setSelectunitPro(selectedOption)
  }
  const handleUnitUnit = (selectedOption) => {
    setSelectunitU(selectedOption)
  }
  const handleAddProduct = (e) => {
    setAddProductList(selectedRows.map((row) => row.rowData));
  }
  const deletefunction = (id) => {
    let indexToDelete = id;

    if (indexToDelete >= 0 && indexToDelete < productlistUnits.length) {
      // Create a new array without the element at the specified index
      const updatedProductList = [...productlistUnits.slice(0, indexToDelete), ...productlistUnits.slice(indexToDelete + 1)];

      // Update the state with the new array
      setAddProductList(updatedProductList);
    } else {
      console.log("Index out of range");
    }
  };
  const [selectedRows, setSelectedRows] = useState([]);

  const toggleRow = (index, rowData) => {
    const isSelected = selectedRows.some((row) => row.index === index);

    if (isSelected) {
      setSelectedRows(selectedRows.filter((row) => row.index !== index));
    } else {
      setSelectedRows([...selectedRows, { index, rowData }]);
    }
  };
  const addPOno = (e) => {
    setPONo(e.target.value)
    productslist(e.target.value)
  }
  console.log(productlistUnits,'oooooo');
  return (
    <>
      <div className="container-fluid">
        <div className="row" >
          <div className="col-xxl-12">
            <div className="card custom-card" id="cart-container-delete">
              <div className="card-header">
                <div className="card-title">
                  Add SRV
                </div>
              </div>
                <Form onSubmit={handleSubmit(onSubmit)} className='mt-5'>
                  <Row>
                    <Form.Group as={Col} md="3" controlId="validationCustom01">
                    <Form.Label>SRV Number</Form.Label>
                      <Form.Control
                        type="text"
                        name='srvNumber'
                        placeholder="SRV Number"
                        {...register("srvNumber")}
                      />
                      <p className="errorMsg pl-3 mt-2">{errors.srvNumber ? errors.srvNumber.message : ''}</p>
                    </Form.Group>
                    <Form.Group as={Col} md="3" controlId="validationCustom02">
                    <Form.Label>Date</Form.Label>
                      <div style={{ border: '1px solid #f3f3f3', width: '100%', padding: 8 }}>
                        <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} />
                      </div>
                    </Form.Group>
                    <Form.Group as={Col} md="3" controlId="validationCustom01">
                    <Form.Label>Supplier</Form.Label>
                      <Select
                        value={selectedsupplier}
                        onChange={handleSelectSupplier}
                        options={supplierlist}
                        isSearchable
                        placeholder="Search PO"
                      />
                      <p className="errorMsg pl-3 mt-2">{msg1}</p>
                    </Form.Group>
                    <Form.Group as={Col} md="3" controlId="validationCustom01">
                    <Form.Label>Currency</Form.Label>
                      <Form.Control
                        type="text"
                        name='currencyCode'
                        placeholder="Currency"
                        {...register("currencyCode")}
                      />
                      <p className="errorMsg pl-3 mt-2">{errors.currencyCode ? errors.currencyCode.message : ''}</p>
                    </Form.Group>
                  </Row>
                  <div className="row card custom-card">
                    <div className="table-responsive col-12">
                    <table className="gridjs-table mt-3">
                        <thead>
                          <tr>
                            <th className='gridjs-th'>Product Code</th>
                            <th className='gridjs-th'>Product Name</th>
                            <th className='gridjs-th'>UnitName</th>                            
                            <th className='gridjs-th'>POQty</th>                           
                            <th className='gridjs-th'>Brand</th>
                            <th className='gridjs-th'>Category</th>
                            <th className='gridjs-th'>Color</th>
                            <th className='gridjs-th'>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {productlistUnits?.map((contact, index) => {
                            return (
                              <tr key={index}>
                                <td className="gridjs-td">{contact.productCode}</td>
                                <td className="gridjs-td">{contact.productName}</td>
                                <td className="gridjs-td">{contact.unitName}</td>
                                <td className="gridjs-td">{contact.poQty}</td>
                                <td className="gridjs-td">{contact.brandName}</td>
                                <td className="gridjs-td">{contact.categoryName}</td>
                                <td className="gridjs-td">{contact.productColour}</td>
                                <td className="gridjs-td">
                                  <div className="hstack gap-2 fs-15">
                                    <a className="btn btn-icon btn-sm btn-light" style={{ marginLeft: 10, marginRight: 10 }} onClick={() => deletefunction(index)}><img src={del} /></a>
                                  </div>
                                </td>
                              </tr>
                            )
                          })}

                        </tbody>
                      </table>
                      <div onClick={productslist} style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 15, marginBottom: 15 }}>
                        <img src={plus} style={{ width: 50, height: 50 }} data-bs-toggle="modal" data-bs-target="#exampleModal" />
                      </div>
                    </div>
                  </div>


                  <div className="d-flex justify-content-center formfilter mt-5">
                    <Button type="submit"  className="mx-2">{loading ?
                      <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "25px" }}>
                        <Oval

                          heigth="20"
                          width="20"
                          color='#12C412'
                          arialLabel='loading'
                        />
                      </div> : "Submit"}</Button>
                  </div>
                </Form>
                </div>
          </div>
        </div>
      </div>

 
      <div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content" style={{ width: 900 }}>
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">Add Product</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <div className="row mt-1">
                {/* <div className="col-12">
                  <div className='row'>
                    <div className='col-4'>
                      <Form.Control
                        type="text"
                        name='PONo'
                        placeholder="PONo"
                        onChange={addPOno}
                      />
                    </div>
                  </div>
                </div> */}
                <PerfectScrollbar>
                  <table className="gridjs-table mt-3">
                    <thead>
                      <tr>
                        <th className='gridjs-th'>Select</th>
                        <th className='gridjs-th'>Product</th>
                        <th className='gridjs-th'>PO Number</th>
                        <th className='gridjs-th'>Brand</th>
                        <th className='gridjs-th'>Category</th>
                      </tr>
                    </thead>
                    <tbody>
                      {prolist?.map((contact, index) => {
                        const isSelected = selectedRows.some((row) => row.index === index);
                        return (
                          <tr key={index}>
                            <td className="gridjs-td">
                              <input
                                type="checkbox"
                                checked={isSelected}
                                onChange={() => toggleRow(index, contact)}
                              />
                            </td>
                            <td className="gridjs-td">{contact.productName}</td>
                            <td className="gridjs-td">{contact.poNumber}</td>
                            <td className="gridjs-td">{contact.brandName}</td>
                            <td className="gridjs-td">{contact.categoryName}</td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </PerfectScrollbar>
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-primary" data-bs-dismiss="modal" aria-label="Close" onClick={handleAddProduct}>Add</button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AddSr
