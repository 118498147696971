import React, { FC, Fragment, useState, useEffect } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { Button, InputGroup, Form } from 'react-bootstrap';
import 'filepond/dist/filepond.min.css';
import "react-multiple-select-dropdown-lite/dist/index.css";
import axios from 'axios';
import Loader from '../../../utils/Loader';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { useNavigate } from 'react-router-dom';
import { Oval } from "react-loader-spinner";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Table } from 'react-bootstrap';
import { toast } from "react-toastify";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import search from "../../../assets/images/search.png";
import edit from "../../../assets/images/edit.svg";
import del from "../../../assets/images/trash.svg";
import ResponsivePagination from 'react-responsive-pagination';
import 'react-responsive-pagination/themes/classic.css';



function Departmentlist() {
    const navigate = useNavigate()
    const schema = yup.object().shape({
        departmentName: yup.string().required("Department Name required"),
    })
    const {
        register,
        handleSubmit,
        reset,
        setValue,
        clearErrors,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    });
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([])
    const [pagination, setPagination] = useState(1);
    const [totalPages, setTotalPages] = useState();
    useEffect(() => {
        onSubmit()
    }, [])
    const pageSize = 20;

    const onSubmit = async (value) => {
        setLoading(true)
        axios({
            url: `${process.env.REACT_APP_SERVER_URL}/api/Department/GetDepartmentList?DepartmentName=${value?.departmentName ? value?.departmentName : ''}&Offset=${value?.offNum?value?.offNum:0}&RecordCount=${pageSize}`,
            method: 'GET',
        }).then((response) => {
            setLoading(false)
            setData(response.data.data)
            setTotalPages(response.data.totalRecords);
        })
            .catch((error) => {
                setLoading(false)
            })
    };
    const changePage = (value) => {  
        let temp = (value - 1) * 20;
        setPagination(temp);
        onSubmit({offNum:temp});
      };
      const ceil = Math.ceil(totalPages / pageSize);
    const addmore = () => {
        navigate('/add-department')
    }
    const editfunction = (data) => {
        navigate('/edit-department', { state: { id: data } });
    }
    const deletefunction = async (id) => {
        const upload = {
            departmentName: '',
            departmentCode: '',
            userId: "0",
            offset: 0,
            recordCount: 0,
            departmentId: parseInt(id),
        }
        setLoading(true)
        confirmAlert({
            title: 'Confirm to submit',
            message: 'Are you sure to do this.',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        try {
                            axios.delete(`${process.env.REACT_APP_SERVER_URL}/api/Department/DeleteDepartmentById`, {
                                data: upload,
                            }).then((response) => {
                                toast.success(response.data.message);
                                setLoading(false);
                                window.location.reload();
                            }).catch((error) => {
                                setLoading(false);
                                console.log(error);
                            });
                        } catch (error) {
                            setLoading(false);
                            console.log(error);
                        }
                    },
                },
                {
                    label: 'No',
                    onClick: () => alert('Click No'),
                },
            ],
        });
    }
    const handleKeyDown = (e) => {
        // Check if the pressed key is "Enter"
        if (e.key === 'Enter') {
            // Call your submit function here
            onSubmit({ departmentName: e.target.value });
        }
    };
    return (
        <>
            {/* <Loader loading={loading} /> */}
            <div className="col-xl-12">
                <div className="card custom-card">
                    <div className="card-header justify-content-between">
                        <div className="card-title">
                            Departments
                        </div>
                        <div className="prism-toggle">
                            <button className="btn btn-sm btn-primary-light" type='button' onClick={addmore}>Add New<i className="ri-code-line ms-2 d-inline-block align-middle"></i></button>
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="table-responsive">
                            <div className="gridjs-search mt-1 mb-2">
                                <input type="search" placeholder="Type a keyword and press enter" name='departmentName' onKeyDown={handleKeyDown}  {...register("departmentName")} aria-label="Type a keyword..." className="gridjs-input gridjs-search-input" />
                            </div>
                            {loading ?
                                <Skeleton count={5} /> : <table className="gridjs-table">
                                    <thead>
                                        <tr>
                                            <th className='gridjs-th' scope="col">SNo</th>
                                            <th className='gridjs-th' scope="col">Department</th>
                                            <th className='gridjs-th' scope="col">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data?.map((contact, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td className="gridjs-td">{index+1}</td>
                                                    <td className="gridjs-td">{contact.departmentName}</td>
                                                    {/* <td><span className="badge bg-success-transparent">{contact.departmentName}</span></td> */}
                                                    <td className="gridjs-td">
                                                        <div className="hstack gap-2 fs-15">
                                                            <a className="btn btn-icon btn-sm btn-light" style={{ marginLeft: 10, marginRight: 10 }} onClick={() => editfunction(contact?.departmentId)}><img src={edit} /></a>
                                                            <a className="btn btn-icon btn-sm btn-light" style={{ marginLeft: 10, marginRight: 10 }} onClick={() => deletefunction(contact?.departmentId)}><img src={del} /></a>
                                                        </div>
                                                    </td>

                                                </tr>)
                                        })}

                                    </tbody>
                                </table>}
                            <div className="gridjs-footer"><div className="gridjs-pagination">
                                <div className="gridjs-pages">
                                    <ResponsivePagination
                                        current={pagination}
                                        total={ceil}
                                        onPageChange={(value) => changePage(value)}
                                    />
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Departmentlist
