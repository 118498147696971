import React, { FC, Fragment, useState } from 'react';
import { Card, Col, InputGroup, Row, Form, Button, Alert } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useLocation, useSearchParams } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import logo from '../assets/images/erp3.svg';

function Register() {
    document.body.classList.add("register-2", "login-page");
    const [Loader, setLoader] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const [showPassword, setShowPassword] = useState(false);
    const [showPassword1, setShowPassword1] = useState(false);
    const usernameRegex = /^(?:\d{10}|[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/;
    const passwordRegex = /^(?=.*[^a-zA-Z0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*\d).+$/;
    const mobileRegex = /^[0-9]{10}$/;
    const schema = yup.object().shape({
        FullName: yup.string().required("Fullname required"),
        UserName: yup.string().required("Username required"),
        PhoneNumber: yup
            .string()
            .required("Mobile number required")
            .matches(mobileRegex, "Mobile number is not valid"),
        Email: yup.string().email().required("Email required"),
        Password: yup
            .string()
            .required("Password required")
            .min(7, "Passwords must be 7 characters or more")
            .max(20, "Max characters limit Reached")
            .matches(
                passwordRegex,
                "Password must contain at least one uppercase letter, one lowercase letter, one number and one special character"
            ),
        ConfirmPassword: yup
            .string()
            .required("Password required")
            .oneOf([yup.ref("Password"), null], "Passwords must match"),
    });
    const {
        register,
        handleSubmit,
        reset,
        clearErrors,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    });

    const onSubmit = async (data) => {
        setLoader(true)
        const upload = {
            UserName: data.UserName,
            Email: data.Email,
            PhoneNumber: data.PhoneNumber,
            Password: data.Password,
            ConfirmPassword: data.ConfirmPassword,
            FullName: data.FullName
        }
        try {
            const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/user/Register`, upload);
            if (response.data.status == 1) {
                toast.success(response.data.message);
                navigate('/')
                setLoader(false)

            }

        } catch (error) {
            setLoader(false)
            console.log(error);
        }
    }
    const showPasswordfunction = () => {
        if (showPassword) {
            setShowPassword(false)
        } else {
            setShowPassword(true)
        }
    }
    const showPasswordfunction1 = () => {
        if (showPassword1) {
            setShowPassword1(false)
        } else {
            setShowPassword1(true)
        }
    }
    return (
        <Fragment>
            <div className="page">
                <div className="page-content">
                    <div className="container">
                        <Row>
                            <div className="col mx-auto mt-5">
                                <div className="row justify-content-center">
                                    <Col md={6} xl={4}>
                                        <Card>
                                            <Card.Body>
                                                <div className="text-center mb-3">
                                                    <h1 className="mb-2">Register</h1>
                                                    <Link to="#">Create New Account</Link>
                                                </div>
                                                <div className="mt-5">
                                                    <Form className="mt-5" onSubmit={handleSubmit(onSubmit)}>
                                                        <InputGroup className="mb-4">
                                                            <InputGroup.Text>
                                                                <i className="fe fe-user"></i>
                                                            </InputGroup.Text>
                                                            <Form.Control type="text" placeholder="Fullname" name="FullName" {...register("FullName")} />
                                                        </InputGroup>
                                                        <p className="errorMsg pl-3 mt-2">{errors.FullName ? errors.FullName.message : ''}</p>
                                                        <InputGroup className="mb-4">
                                                            <InputGroup.Text>
                                                                <i className="fe fe-phone"></i>
                                                            </InputGroup.Text>
                                                            <Form.Control placeholder="Mobile" name="PhoneNumber" {...register("PhoneNumber")} type="Mobile number" />
                                                        </InputGroup>
                                                        <p className="errorMsg pl-3 mt-2">{errors.PhoneNumber ? errors.PhoneNumber.message : ''}</p>
                                                        <InputGroup className="mb-4">
                                                            <InputGroup.Text>
                                                                <i className="fe fe-mail"></i>
                                                            </InputGroup.Text>
                                                            <Form.Control placeholder="Email" name="Email" {...register("Email")} type="email" />
                                                        </InputGroup>
                                                        <p className="errorMsg pl-3 mt-2">{errors.Email ? errors.Email.message : ''}</p>
                                                        <InputGroup className="mb-4">
                                                            <InputGroup.Text>
                                                                <i className="fe fe-user"></i>
                                                            </InputGroup.Text>
                                                            <Form.Control type="text" name="UserName" {...register("UserName")} placeholder="Username" />
                                                        </InputGroup>
                                                        <p className="errorMsg pl-3 mt-2">{errors.UserName ? errors.UserName.message : ''}</p>
                                                        <InputGroup className="mb-4">
                                                            <span className="input-group-text"><i className="fe fe-lock"></i></span>
                                                            <Form.Control name="Password" {...register("Password")} type="password" placeholder="Password" />
                                                        </InputGroup>
                                                        <p className="errorMsg pl-3 mt-2">{errors.Password ? errors.Password.message : ''}</p>
                                                        <InputGroup className="mb-4">
                                                            <span className="input-group-text"><i className="fe fe-lock"></i></span>
                                                            <Form.Control name="ConfirmPassword" {...register("ConfirmPassword")} type="password" placeholder="Confirm password" />
                                                        </InputGroup>
                                                        <p className="errorMsg pl-3 mt-2">{errors.ConfirmPassword ? errors.ConfirmPassword.message : ''}</p>
                                                        <div className="form-group text-center mb-3">
                                                            <button type='submit' className="btn btn-primary w-100 br-7">Sign Up{Loader ? <span role="status" aria-hidden="true" className="spinner-border spinner-border-sm ms-2"></span> : ""}</button>
                                                        </div>
                                                    </Form>
                                                    <Form.Group className="form-group fs-14 text-center font-weight-bold">
                                                        <Link to={'/'} >If already have an Account?login</Link>
                                                    </Form.Group>

                                                    <div className="form-group fs-12 text-center">
                                                        By Clicking Sign up,Your agree to our  <Link to="#" className="font-weight-bold">Terms & Conditions</Link> and have read and acknowledge our  <a href="#" className="font-weight-bold">Privacy & Services.</a>
                                                    </div>
                                                </div>
                                            </Card.Body>
                                        </Card>
                                        <div className="text-center register-icons">
                                            <div className="small text-white mb-4">Register using with</div>
                                            <Button className="btn bg-white-50  text-white-50 font-weight-semibold w-12 google me-2" type="button" variant='light'><i className="fa fa-google"></i></Button>
                                            <Button className="btn bg-white-50  text-white-50 font-weight-semibold  w-12 facebook me-2" type="button" variant='light'><i className="fa fa-facebook-f"></i></Button>
                                            <Button className="btn bg-white-50  text-white-50 font-weight-semibold w-12 twitter me-2" type="button" variant='light'><i className="fa fa-twitter"></i></Button>
                                        </div>
                                    </Col>
                                </div>
                            </div>
                        </Row>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default Register
