import React, { FC, Fragment, useState, useEffect } from 'react';
import { Button, Card, Col, InputGroup, Row, Form, Alert, Nav, Tab } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Oval } from "react-loader-spinner";
import Select from 'react-select';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import edit from "../../assets/images/edit.svg";
import del from "../../assets/images/trash.svg";
import plus from "../../assets/images/plus.png";

function ApprovePo() {
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate();
    const location = useLocation();
    const schema = yup.object().shape({
    })
    const {
        register,
        handleSubmit,
        reset,
        setValue,
        clearErrors,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    });
    useEffect(() => {
        units()
        Supplierlist()
        projectlist()
        productslist()
        prolistbyid()
    }, [])
    const [msg1, setMsg1] = useState();
    const [msg2, setMsg2] = useState();
    const [startDate, setStartDate] = useState(new Date());
    const [unitlist, setUnits] = useState([])
    const [prolist, setPro] = useState([])
    const [projectlists, setProjectList] = useState([])
    const [selectedsupplier, setSelectedSupplier] = useState([])
    const [productprice, setProductPrice] = useState([])
    const [selectedprojects, setSelectedProjects] = useState([])
    const [selectunitU, setSelectunitU] = useState([])
    const [supplierlist, setSupplierList] = useState([])
    const [selectunitPro, setSelectunitPro] = useState([])
    const [selectunitqty, setSelectunitQty] = useState('')
    const [productlistUnits, setAddProductList] = useState([])
    const [approvallist, setApprovallist] = useState([])
    const prolistbyid = () => {
        axios({
            url: `${process.env.REACT_APP_SERVER_URL}/api/PO/GetPOById?poid=${parseInt(location.state.id)}`,
            method: 'GET',
        }).then((res) => {
            setApprovallist(res.data.data.poApprovals)
            setValue("poNumber", res.data.data.poNumber)
            setStartDate(res.data.data.poDate ? new Date(res.data.data.poDate) : new Date())
            setSelectedSupplier({
                label: res.data.data.supplierName,
                value: res.data.data.poSupplierId
            })
            setSelectedProjects({
                label: res.data.data.projectName,
                value: res.data.data.poProjectId
            })
            setAddProductList(res.data.data.pOdetailModel)
        })
            .catch((error) => {
                setLoading(false)
            })
    }
    const units = async (value) => {
        axios({
            url: `${process.env.REACT_APP_SERVER_URL}/api/Unit/GetUnitList`,
            method: 'GET',
        }).then((response) => {
            const formattedData = response.data.data.map(item => ({
                label: item.unitCode,
                value: item.unitId
            }));
            setUnits(formattedData)
        })
            .catch((error) => {
            })
    };
    const productslist = async (value) => {
        axios({
            url: `${process.env.REACT_APP_SERVER_URL}/api/Product/GetProductList?ProductCode&UnitConvertion`,
            method: 'GET',
        }).then((response) => {
            const formattedData = response.data.data.map(item => ({
                label: item.productName,
                value: item.productId
            }));
            setPro(formattedData)
        })
            .catch((error) => {
            })
    };
    const Supplierlist = async (value) => {
        axios({
            url: `${process.env.REACT_APP_SERVER_URL}/api/Supplier/GetSupplierList`,
            method: 'GET',
        }).then((response) => {
            const formattedData = response.data.data.map(item => ({
                label: item.supName,
                value: item.supplierId
            }));
            setSupplierList(formattedData)
        })
            .catch((error) => {
            })
    };
    const projectlist = async (value) => {
        axios({
            url: `${process.env.REACT_APP_SERVER_URL}/api/Project/GetProjectList`,
            method: 'GET',
        }).then((response) => {
            const formattedData = response.data.data.map(item => ({
                label: item.projectName,
                value: item.projectId
            }));
            setProjectList(formattedData)
        })
            .catch((error) => {
            })
    };
    const onSubmit = async (data) => {
        const upload = {
            userId: "string",
            poDate: startDate,
            poNumber: data?.poNumber,
            poSupplierId: selectedsupplier?.value,
            poProjectId: selectedprojects?.value,
            poTotal: data?.poTotal,
            pO_details: productlistUnits
        }
        setLoading(true)
        try {
            const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/PO/CreatePO`, upload);
            if (response.data.status == 1) {
                toast.success(response.data.message);
                navigate('/PO-list')
                setLoading(false)
            }

        } catch (error) {
            setLoading(false)
            console.log(error);
        }
    }

    const handleSelectSupplier = (selectedOption) => {
        setSelectedSupplier(selectedOption);
    };
    const handleSelectProject = (selectedOption) => {
        setSelectedProjects(selectedOption);
    };
    const handleUnitProduct = (selectedOption) => {
        setSelectunitPro(selectedOption)
    }
    const handleUnitUnit = (selectedOption) => {
        setSelectunitU(selectedOption)
    }
    const handleAddProduct = (e) => {
        if (selectunitPro) {
            setAddProductList([...productlistUnits, {
                productname: selectunitPro?.label,
                productid: selectunitPro?.value,
                altunitname: selectunitU?.label,
                altunitid: selectunitU?.value,
                conversionfactor: selectunitqty,
                prodprice: productprice
            }]);
            // Reset the input values
            setSelectunitPro(null);
            setSelectunitU(null);
            setSelectunitQty('');
        }
    }
    const deletefunction = (id) => {
        let indexToDelete = id;

        if (indexToDelete >= 0 && indexToDelete < productlistUnits.length) {
            // Create a new array without the element at the specified index
            const updatedProductList = [...productlistUnits.slice(0, indexToDelete), ...productlistUnits.slice(indexToDelete + 1)];

            // Update the state with the new array
            setAddProductList(updatedProductList);
        } else {
            console.log("Index out of range");
        }
    };
    const approvetheMR = () => {
        axios({
            url: `${process.env.REACT_APP_SERVER_URL}/api/PO/ApprovalPO`,
            method: 'POST',
            data: {
                userId: "string",
                deparmentId: 0,
                approvalStatus: 1,
                poId: parseInt(location.state.id),
                approvalDate: new Date()
            },
        }
        ).then((response) => {
            toast.success("PO Approved successfully ")
            // navigate('/PO-list')
        })
            .catch((error) => {
                toast.error("something went wrong")
                console.log(error);
            })
    }
    const rejecttheMR = () => {
        axios({
            url: `${process.env.REACT_APP_SERVER_URL}/api/PO/ApprovalPO`,
            method: 'POST',
            data: {
                userId: "string",
                deparmentId: 0,
                approvalStatus: 0,
                poId: parseInt(location.state.id),
                approvalDate: new Date(),
                employeeId: 0,
            },
        }
        ).then((response) => {
            toast.success("PO Rejected successfully ")
            // navigate('/PO-list')
        })
            .catch((error) => {
                toast.error("something went wrong")
                console.log(error);
            })
    }
    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            backgroundColor: state.isDisabled ? 'white' : provided.backgroundColor,
        }),
    };
    return (
        <>
            <div className="container-fluid">
                <div className="row" >
                    <div className="col-xxl-12">
                        <div className="card custom-card" id="cart-container-delete">
                            <div className="card-header">
                                <div className="card-title">
                                    Approve PO
                                </div>
                            </div>
                            <div className="pt-3">
                                <Row>
                                    <Form.Group as={Col} md="3" controlId="validationCustom01">
                                        <Form.Control
                                            type="text"
                                            name='poNumber'
                                            placeholder="Code"
                                            {...register("poNumber")}
                                            disabled
                                            style={{ backgroundColor: 'white' }}

                                        />
                                        <p className="errorMsg pl-3 mt-2">{errors.poNumber ? errors.poNumber.message : ''}</p>
                                    </Form.Group>
                                    <Form.Group as={Col} md="3" controlId="validationCustom02">
                                        <div style={{ border: '1px solid #f3f3f3', width: '100%', padding: 8 }}>
                                            <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} disabled />
                                        </div>
                                    </Form.Group>
                                    <Form.Group as={Col} md="3" controlId="validationCustom01">
                                        <Select
                                            value={selectedsupplier}
                                            onChange={handleSelectSupplier}
                                            options={supplierlist}
                                            isSearchable
                                            placeholder="Search Supplier"
                                            isDisabled
                                            styles={customStyles}
                                        />
                                        <p className="errorMsg pl-3 mt-2">{msg1}</p>
                                    </Form.Group>
                                    <Form.Group as={Col} md="3" controlId="validationCustom01">
                                        <Select
                                            value={selectedprojects}
                                            onChange={handleSelectProject}
                                            options={projectlists}
                                            isSearchable
                                            placeholder="Search Projects"
                                            isDisabled
                                            styles={customStyles}
                                        />
                                        <p className="errorMsg pl-3 mt-2">{msg2}</p>
                                    </Form.Group>
                                </Row>
                            </div>
                            <div className="row">
                                <div className="table-responsive col-8">
                                    <table className="gridjs-table mt-3">
                                        <thead>
                                            <tr>
                                                <th className='gridjs-th'>Product</th>
                                                <th className='gridjs-th'>Unit</th>
                                                <th className='gridjs-th'>Quantity</th>
                                                <th className='gridjs-th'>Price</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {productlistUnits?.map((contact, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td className="gridjs-td">{contact.productname ? contact.productname : contact.productName}</td>
                                                        <td className="gridjs-td">{contact.altunitname ? contact.altunitname : contact.unit}</td>
                                                        <td className="gridjs-td">{contact.conversionfactor ? contact.conversionfactor : contact.prodQty}</td>
                                                        <td className="gridjs-td">{contact.prodprice ? contact.prodprice : contact.prodPrice}</td>
                                                    </tr>
                                                )
                                            })}

                                        </tbody>
                                    </table>
                                    <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 10, marginBottom: 10 }}>
                                    </div>
                                    <div className="row card-body">
                                <div className="col-6 p-1">
                                    <div className="row">
                                        <div className="col-5">
                                            <Button className="mr-3 btn btn-danger btn-raised-shadow btn-wave waves-effect waves-light btn-w-sm" type="button" onClick={() => rejecttheMR()} >Reject</Button>
                                        </div>
                                        <div className="col-5">
                                            <Button className="btn btn-success btn-w-sm btn-wave waves-effect waves-light" type="button" onClick={() => approvetheMR()}>Approve</Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                                </div>
                                <div className="col-4">
                                    <Card className="mt-3" style={{ height: 'auto' }}>
                                        <Card.Header>
                                            <Card.Title>
                                                Approve Details
                                            </Card.Title>
                                        </Card.Header>
                                        <Card.Body className="p-0" style={{ maxHeight: 300 }}>
                                            <ul className="recent-activity" key={Math.random()}>
                                                {approvallist?.map((Product, index) => (
                                                    <li className="mb-1 mt-3 border-bottom">
                                                        <span className="font-weight-normal1 fs-13">{Product.departmentName}</span>
                                                        <span className="text-muted fs-12 float-end">{Product.approvalDate?.slice(0, 10)}</span>

                                                        <p className="main-header-container"> <span className="text-muted fs-12 mt-1"> {Product.employeeName}</span> 
                                                        <span className={Product.approvalStatus == 1 ? 'badge bg-success' : 'badge bg-danger'}>{Product.approvalStatus == 1 ? 'Approved' : 'Rejected'}</span>
                                                         </p>
                                                    </li>
                                                ))}
                                            </ul>
                                        </Card.Body>
                                    </Card>
                                </div>
                            </div>
                           
                        </div>
                    </div>
                </div>
                <div className="row card custom-card">

                </div>

            </div>
            <ToastContainer />
        </>
    )
}

export default ApprovePo
