import React, { FC, Fragment, useState, useEffect } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { Button, InputGroup, Form } from 'react-bootstrap';
import 'filepond/dist/filepond.min.css';
import "react-multiple-select-dropdown-lite/dist/index.css";
import axios from 'axios';
import Loader from '../../utils/Loader';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { useNavigate } from 'react-router-dom';
import { Oval } from "react-loader-spinner";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Table } from 'react-bootstrap';
import { toast } from "react-toastify";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import search from "../../assets/images/search.png";
import edit from "../../assets/images/edit.svg";
import del from "../../assets/images/trash.svg";
import ResponsivePagination from 'react-responsive-pagination';
import 'react-responsive-pagination/themes/classic.css';
import Select from 'react-select';
import { utils as XLSXUtils, writeFile as writeXLSX } from 'xlsx';


function Paymentlist() {
  const navigate = useNavigate()
  const [pagination, setPagination] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedContacts, setSelectedContacts] = useState([]);
  const [totalPages, setTotalPages] = useState();
  const [selectedPost, setSelectedPost] = useState(null);
  const [isPostedChecked, setPostedChecked] = useState(false);
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');

  const pageSize = 20;
  const schema = yup.object().shape({
    PaymentName: yup.string().required("Payment Name required"),
  })
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([])
  const [search, setSearch] = useState('')
  const [excelData, setExcelData] = useState([])
  useEffect(() => {
    onSubmit();
    const today = new Date();
    const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 2);

    setFromDate(firstDayOfMonth.toISOString().split('T')[0]);
    setToDate(today.toISOString().split('T')[0]);
  }, [])

  const onSubmit = async (value) => {
    setLoading(true)
    axios({
      url: `${process.env.REACT_APP_SERVER_URL}/api/Payment/GetPaymentList?description=${value?.PaymentName ? value?.PaymentName : ''}&Offset=${value?.offNum ? value?.offNum : 0}&RecordCount=${pageSize}&posted=${isPostedChecked ? '1' : '0' || ''}`,
      method: 'GET',
    }).then((response) => {
      setLoading(false)
      setData(response.data.data)
      setTotalPages(response.data.totalRecords);
      
    })
      .catch((error) => {
        setLoading(false)
      })
  };
  const addmore = () => {
    navigate('/add-Payment')
  }
  const editfunction = (data) => {
    navigate('/edit-payment', { state: { id: data } });
  }
  const deletefunction = async (id) => {
    const upload = {
      Id: parseInt(id),
    }
    setLoading(true)
    confirmAlert({
      title: 'Confirm to submit',
      message: 'Are you sure to do this.',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            try {
              axios.delete(`${process.env.REACT_APP_SERVER_URL}/api/Payment/DeletePaymentById`, {
                data: upload,
              }).then((response) => {
                toast.success(response.data.message);
                setLoading(false);
                window.location.reload();
                
              }).catch((error) => {
                setLoading(false);
                console.log(error);
              });
            } catch (error) {
              setLoading(false);
              console.log(error);
            }
          },
        },
        {
          label: 'No',
          onClick: () => alert('Click No'),
        },
      ],
    });
  }
  const handleKeyDown = (e) => {
    // Check if the pressed key is "Enter"
    if (e.key === 'Enter') {
      // Call your submit function here
      onSubmit({ PaymentName: e.target.value });
      setSearch(e.target.value);

    }
  };
  const changePage = (value) => {
    let temp = (value - 1) * 20;
    setPagination(temp);
  setCurrentPage(value);

    onSubmit({ offNum: temp });
};
const ceil = Math.ceil(totalPages / pageSize);

const formatDate = (dateString) => {
  const date = new Date(dateString);
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  return `${day < 10 ? '0' : ''}${day}-${month < 10 ? '0' : ''}${month}-${year}`;
};
const handleCheckboxChange = (contactId,Id) => {
  const isSelected = selectedContacts.includes(contactId);
  const upload = {
    Id: parseInt(Id),
  }

  if (isSelected) {
    //try {
      // axios.post(`${process.env.REACT_APP_SERVER_URL}/api/Payment/UpdatePost`, {
      //   data: upload,
      // }).then((response) => {
      //   toast.success(response.data.message);
      //   setLoading(false);
      //   onSubmit();
      // }).catch((error) => {
      //   setLoading(false);
      //   console.log(error);
      // });
      try {
        const response =  axios.post(`${process.env.REACT_APP_SERVER_URL}/api/Payment/UpdatePost`, upload);
        console.log("As"+response.status)
      
          onSubmit();
            //toast.success(response.data.message);
            navigate('/payment-list')
            setLoading(false)
           
     
        

    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  
  } else {
    setSelectedContacts((prevSelected) => [...prevSelected, contactId]);
  }
};

const handleSelectChange = (selectedOption) => {
  setSelectedPost(selectedOption);
};

const handlePostedCheckboxChange = () => {
  setPostedChecked(!isPostedChecked);
  onSubmit();
};



async function onSubmitExcel(value) {
  try {
    // Show loading indicator
    setLoading(true);

    // Fetch data from API

    setTimeout(async () => {
      
      console.log('Excel data prepared.');
 
    const response = await axios({
      url: `${process.env.REACT_APP_SERVER_URL}/api/Payment/GetPaymentExcelList`,
      method: 'GET',
      params: {
        description: value?.Description || '',
        FromDate: value?.FromDate || '',
        ToDate: value?.ToDate || ''
      }
    });

    // Set fetched data to state
    setExcelData(response.data.data);

  }, 3000); 


    
    setLoading(false);
  } catch (error) {
    console.error('Error fetching data or creating Excel file:', error);

    setLoading(false);
  }
}

const exportToExcel = () => {

    onSubmitExcel({ description: search,FromDate:fromDate,ToDate:toDate});

  if(excelData!=null){
     const ws = XLSXUtils.json_to_sheet(excelData);
   const wb = XLSXUtils.book_new();
   XLSXUtils.book_append_sheet(wb, ws, 'SheetName'); // Provide a sheet name
     writeXLSX(wb, 'exported_data.xlsx');
     setExcelData([]);
 };
}

  return (
    <>
      <Loader loading={loading} />
      <div class="col-xl-12">
        <div class="card custom-card">
          <div class="card-header justify-content-between">
            <div class="card-title">
              Payments
            </div>
            <div class="ms-auto">
              <label htmlFor="fromDate">From Date:</label>
              <input
                type="date"
                id="fromDate"
                className="form-control"
                value={fromDate}
                onChange={(e) => setFromDate(e.target.value)}
              />
            </div>
            <div class="ms-auto">
              <label htmlFor="toDate">To Date:</label>
              <input
                type="date"
                id="toDate"
                className="form-control"
                value={toDate}
                onChange={(e) => setToDate(e.target.value)}
              />
            </div>
            <div class="ms-auto">
              <button class="border-primary btn btn-outline-primary" onClick={exportToExcel} >
              <i class="fe fe-download me-2"></i>Excel Download</button> 
    </div>
            <div class="prism-toggle">
              <button class="btn btn-sm btn-primary-light" type='button' onClick={addmore}>Add New<i class="ri-code-line ms-2 d-inline-block align-middle"></i></button>
            </div>
          </div>
          <div class="card-body">
            <div class="table-responsive">
              <div class="gridjs-search mt-1 mb-2">
                <input type="search" placeholder="Type a keyword..." name='PaymentName' onKeyDown={handleKeyDown}  {...register("PaymentName")} aria-label="Type a keyword..." class="gridjs-input gridjs-search-input"  />
          
               
  {/* <Select
    
    name="post"
    options={[
      { value: "2", label: "All" },
      { value: "1", label: "Paid" },
      { value: "0", label: "Not Paid" },
    ]}
    styles={{
      control: (provided) => ({
        ...provided,
        width: '200px',
      }),
      menu: (provided) => ({
        ...provided,
        width: '200px', // Set the desired width for the dropdown menu
      }),
    }}
    onChange={handleSelectChange} 
  /> */}
      </div>
             
              <table class="gridjs-table">
                <thead>
                  <tr>
                    {/* <th className='gridjs-th'>Sl NO</th> */}
                    <th className='gridjs-th'>Expense Group</th>
                    <th className='gridjs-th'>Description</th>
                    <th className='gridjs-th'>Date</th>
                    <th className='gridjs-th'>Amount</th>
                    <th className='gridjs-th'>Mode </th>
                    <th className='gridjs-th'><input
            type="checkbox"
            checked={isPostedChecked}
            onChange={handlePostedCheckboxChange}
          />Posted </th>
                    <th className='gridjs-th'>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {data?.map((contact, index) => {
                    return (
                      <tr key={index}>
                        {/* <td class="gridjs-td">{index + 1}</td> */}
                        <td class="gridjs-td">{contact.expenseType}</td>
                        <td class="gridjs-td">{contact.description}</td>
                        <td class="gridjs-td">{formatDate(contact.paymentDate)}</td>
                        <td class="gridjs-td">{contact.amount}</td>
                        <td class="gridjs-td">{contact.mode}</td>
                        <td className="gridjs-td">
              <input
                type="checkbox"
                checked={(contact.posted)}
                onChange={() => handleCheckboxChange(contact.posted,contact.id)}
              />
            </td>
                        <td class="gridjs-td">
                          <div class="hstack gap-2 fs-15">
                            <a class="btn btn-icon btn-sm btn-light" style={{ marginLeft: 10, marginRight: 10 }} onClick={() => editfunction(contact?.id)} ><img src={edit} /></a>
                            <a class="btn btn-icon btn-sm btn-light" style={{ marginLeft: 10, marginRight: 10 }} onClick={() => deletefunction(contact?.id)}><img src={del} /></a>
                          </div>
                        </td>
                      </tr>
                    )
                  })}

                </tbody>
              </table>
              </div>
              <div class="gridjs-footer"><div className="gridjs-pagination">
                                <div className="gridjs-pages">
                                    <ResponsivePagination
                                        current={currentPage}
                                        total={ceil}
                                        onPageChange={(value) => changePage(value)}
                                    />
                                </div>
                            </div>
             </div>
          
            </div>
          </div>
        </div>
      
    </>
  )
}

export default Paymentlist
