import React, { FC, Fragment, useState, useEffect } from 'react';
import { Button, Card, Col, InputGroup, Row, Form, Alert, Nav, Tab } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useLocation, useSearchParams } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import PageHeader from '../../layouts/pageheader';
import { Oval } from "react-loader-spinner";
import Select from 'react-select';
import DatePicker from "react-datepicker";

function AddExpense() {
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate();
    const location = useLocation();
    const schema = yup.object().shape({
    })
    const {
        register,
        handleSubmit,
        reset,
        setValue,
        clearErrors,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    });
    const [startDate, setStartDate] = useState(new Date());
    const [expenseTypeList, setExpenseTypeList] = useState([]);
    const [selectedExpenseType, setSelectedExpenseType]= useState([]);

    const handleSelectExpenseType = (selectedOption) => {
        setSelectedExpenseType(selectedOption);

    
    };
    const onSubmit = async (data) => {
        const upload = {
            userId: "string",
            expenseType:selectedExpenseType.value,
            name: data?.name,
            amount: data?.amount,
            expDate: startDate,
            remarks: data?.remarks,
            ExpenseID: 0
        }
        setLoading(true)
        try {
            const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/Expense/CreateExpense`, upload);
            if (response.data.status == 1) {
                toast.success(response.data.message);
                navigate('/Expense-list')
                setLoading(false)
            }

        } catch (error) {
            setLoading(false)
            console.log(error);
        }
    }
    useEffect(() => {
        expensetypelist()
        
    }, [])


    const expensetypelist = async (value) => {
        axios({
            url: `${process.env.REACT_APP_SERVER_URL}/api/ExpenseType/GetExpenseTypeList`,
            method: 'GET',
        }).then((response) => {
            const formattedData = response.data.data.map(item => ({
                label: item.expenseTypeName,
                value: item.expenseTypeId
            }));
            setExpenseTypeList(formattedData)
        })
            .catch((error) => {
            })
    };
  return (
    <Fragment>
            <Fragment>
                <div className='page-header'>
                    <div className="page-leftheader">
                        <h4 className="page-title mb-0">Add Expense</h4>
                    </div>
                    <div className="page-rightheader">
                        <div className='d-flex align-items-center'>

                        </div>
                    </div>
                </div>
            </Fragment>
            <Row>
                <Col xl={4} md={12} sm={12}>
                </Col>
                <Col xl={4} md={12} sm={12}>
                    <Card>
                        <Card.Body>
                            <Form onSubmit={handleSubmit(onSubmit)}>
                              
                                <Form.Group as={Col} md="12" controlId="validationCustom01">
                                        <Select
                                            value={selectedExpenseType}
                                            onChange={handleSelectExpenseType}
                                            options={expenseTypeList}
                                            isSearchable
                                            placeholder="Search Expense Type"
                                        />
                                        <p className="errorMsg pl-3 mt-2">{errors.designationName ? errors.designationName.message : ''}</p>
                                    </Form.Group>
                                    <Form.Group as={Col} md="12" controlId="validationCustom01">
                                    <Form.Control
                                        type="text"
                                        name='name'
                                        placeholder="Expense"
                                        {...register("name")}
                                    />
                                    <p className="errorMsg pl-3 mt-2">{errors.name ? errors.name.message : ''}</p>
                                </Form.Group>
                                <Form.Group as={Col} md="12" controlId="validationCustom01">
                                    <Form.Control
                                        type="number"
                                        name='amount'
                                        placeholder="Amount"
                                        {...register("amount")}
                                    />
                                    <p className="errorMsg pl-3 mt-2">{errors.amount ? errors.amount.message : ''}</p>
                                </Form.Group>

                                <Form.Group as={Col} md="2" controlId="validationCustom02">
                                        <div style={{ border: '1px solid #f3f3f3', width: '100%', padding: 8 }}>
                                            <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} />
                                        </div>
                                    </Form.Group>

                                <Form.Group as={Col} md="12" controlId="validationCustom01">
                                    <Form.Control
                                        type="text"
                                        name='remarks'
                                        placeholder="Remarks"
                                        {...register("remarks")}
                                    />
                                    <p className="errorMsg pl-3 mt-2">{errors.remarks ? errors.remarks.message : ''}</p>
                                </Form.Group>
                                
                              
                              
                       
                                <div className="d-flex justify-content-center formfilter mt-5">
                                    <Button type="submit" className="mx-2">{loading ?
                                        <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "25px" }}>
                                            <Oval

                                                heigth="20"
                                                width="20"
                                                color='#12C412'
                                                arialLabel='loading'
                                            />
                                        </div> : "Submit"}</Button>
                                </div>
                            </Form>
                        </Card.Body>
                    </Card>
                </Col>
                <Col xl={4} md={12} sm={12}>
                </Col>
            </Row>

        </Fragment>
  )
}

export default AddExpense
