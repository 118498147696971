import React, { FC, Fragment, useState, useEffect } from 'react';
import { Button, Card, Col, InputGroup, Row, Form, Alert, Nav, Tab } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useLocation, useSearchParams } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
// import PageHeader from '../../../layouts/pageheader';
import { Oval } from "react-loader-spinner";
import Select from 'react-select';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function EditBrand() {
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate();
    const location = useLocation();
    const schema = yup.object().shape({
        brandName: yup.string().required("Name required"),
    })
    useEffect(() => {
        setLoading(true)
        axios({
            url: `${process.env.REACT_APP_SERVER_URL}/api/ProductBrand/GetProductBrandById?brandid=${parseInt(location.state.id)}`,
            method: 'GET',
        }).then((response) => {
            setLoading(false)
            setValue("brandName", response.data.data.brandName)
        })
            .catch((error) => {
                setLoading(false)
            })
    }, [])
    const {
        register,
        handleSubmit,
        reset,
        setValue,
        clearErrors,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    });
    const onSubmit = async (data) => {
        const upload = {
            userId: "string",
            brandName: data?.brandName,
            brandId: parseInt(location.state.id)
        }
        setLoading(true)
        try {
            const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/ProductBrand/UpdateProductBrand`, upload);
            if (response.data.status == 1) {
                toast.success(response.data.message);
                navigate('/brand-list')
                setLoading(false)
            }

        } catch (error) {
            setLoading(false)
            console.log(error);
        }
    }
    return (
        <Fragment>
            <Fragment>
                <div className='page-header'>
                    <div className="page-leftheader">
                        <h4 className="page-title mb-0">Edit Brand</h4>
                    </div>
                    <div className="page-rightheader">
                        <div className='d-flex align-items-center'>

                        </div>
                    </div>
                </div>
            </Fragment>
            <Row>
                <Col xl={4} md={12} sm={12}>
                </Col>
                <Col xl={4} md={12} sm={12}>
                    <Card>
                        <Card.Body>
                            <Form onSubmit={handleSubmit(onSubmit)}>
                                <Form.Group as={Col} md="12" controlId="validationCustom01">
                                    <Form.Control
                                        type="text"
                                        name='brandName'
                                        placeholder="Employee Name"
                                        {...register("brandName")}
                                    />
                                    <p className="errorMsg pl-3 mt-2">{errors.brandName ? errors.brandName.message : ''}</p>
                                </Form.Group>
                                <div className="d-flex justify-content-center formfilter mt-5">
                                    <Button type="submit" className="mx-2">{loading ?
                                        <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "25px" }}>
                                            <Oval

                                                heigth="20"
                                                width="20"
                                                color='#12C412'
                                                arialLabel='loading'
                                            />
                                        </div> : "Submit"}</Button>
                                </div>
                            </Form>
                        </Card.Body>
                    </Card>
                </Col>
                <Col xl={4} md={12} sm={12}>
                </Col>
            </Row>

        </Fragment>
    )
}

export default EditBrand
