import React, { useState, useEffect } from "react";
import { MultiSelect } from "react-multi-select-component";
import edit from "../../assets/images/edit.svg";
import del from "../../assets/images/trash.svg";
import plus from "../../assets/images/plus.png";
import { confirmAlert } from 'react-confirm-alert';
import axios from 'axios';
import { Card, Col, Row } from 'react-bootstrap';
import { Button, InputGroup, Form } from 'react-bootstrap';
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import ResponsivePagination from 'react-responsive-pagination';
import 'react-responsive-pagination/themes/classic.css';
import { useNavigate } from 'react-router-dom';
import FloatGroup from 'react-float-button';
import Select from 'react-select';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useLocation, useSearchParams } from "react-router-dom";

function ApproveMr() {
    const navigate = useNavigate()
    const location = useLocation();
    const [currentPage, setCurrentPage] = useState(1);
    const totalPages = 1;
    const schema = yup.object().shape({
        designationName: yup.string().required("Designation Name required"),
    })
    const {
        register,
        handleSubmit,
        reset,
        setValue,
        clearErrors,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    });
    const [addProductList, setAddProductList] = useState([]);
    const [startDate, setStartDate] = useState(new Date());
    const [startDate1, setStartDate1] = useState(new Date());
    const [mrcity, setMrcity] = useState('');
    const [mrnum, setNum] = useState('');
    const [Qnty, setQnty] = useState(0);
    const [price, setPrice] = useState(0);
    const [units, setUnits] = useState([]);
    const [mrcategory, setMrcategory] = useState([]);
    const [productList, setProductList] = useState([]);
    const [projectList, setProjectList] = useState([]);
    const [employeeList, setEmployeeList] = useState([]);
    const [selectedUnits, setSelectedUnits] = useState([]);
    const [selectedMrcategory, setSelectedMrcategory] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState([]);
    const [selectedProject, setSelectedProject] = useState([]);
    const [selectedEmployee, setSelectedEmployee] = useState([]);
    const [selectedPrio, setSelectedPrio] = useState([]);
    const [approvallist,setApprovallist] = useState([])
    const handleSelectproject = (selectedOption) => {
        setSelectedProject(selectedOption);
    };
    const handleSelectEmploee = (selectedOption) => {
        setSelectedEmployee(selectedOption);
    };
    const handleSelectMr = (selectedOption) => {
        setSelectedMrcategory(selectedOption);
    };
    const handleSelectProduct = (selectedOption) => {
        setSelectedProduct(selectedOption);
    };
    const handleSelectUnits = (selectedOption) => {
        setSelectedUnits(selectedOption);
    };
    const handleSelectPrio = (selectedOption) => {
        setSelectedPrio(selectedOption);
    };
    const mrcityname = (e) => {
        setMrcity(e.target.value)
    }
    const mrNumber = (e) => {
        setNum(e.target.value)
    }
    useEffect(() => {
        unitlist()
        onSubmit()
        mrcategorylist()
        productlist()
        projectlist()
        employeelist()
        mrdatalistbyid()
    }, [])
    const mrdatalistbyid = async => {
        axios({
            url: `${process.env.REACT_APP_SERVER_URL}/api/MR/GetMRById?mrid=${parseInt(location.state.id)} `,
            method: 'GET',
        }).then((res) => {
            setApprovallist(res.data.data.mrApprovals)
            setSelectedProject({
                label: res.data.data.projectName,
                value: res.data.data.mrProjectId
            })
            setSelectedEmployee({
                label: res.data.data.employeeName,
                value: res.data.data.mrEmployeeId
            })
            setSelectedMrcategory({
                label: res.data.data.categoryName,
                value: res.data.data.mrCategoryId
            })
            setSelectedPrio({
                label: res.data.data.mrStatus === 0 ? 'Major' : 'Minor',
                value: res.data.data.mrStatus
            })
            setAddProductList(res.data.data.materialRequestDetails)

            setNum(res.data.data.mrNumber)
            setStartDate1(res.data.data.mrDate ? new Date(res.data.data.mrDate) : new Date())
        })
            .catch((error) => {
            })
    }
    const unitlist = async (value) => {
        axios({
            url: `${process.env.REACT_APP_SERVER_URL}/api/Unit/GetUnitList`,
            method: 'GET',
        }).then((response) => {
            const formattedData = response.data.data.map(item => ({
                label: item.unitCode,
                value: item.unitId
            }));
            setUnits(formattedData)
        })
            .catch((error) => {
            })
    };
    const mrcategorylist = async (value) => {
        axios({
            url: `${process.env.REACT_APP_SERVER_URL}/api/MRCategory/GetMRCategoryList`,
            method: 'GET',
        }).then((response) => {
            const formattedData = response.data.data.map(item => ({
                label: item.mrCategoryName,
                value: item.mrCategoryId
            }));
            setMrcategory(formattedData)
        })
            .catch((error) => {
            })
    };
    const productlist = async (value) => {
        axios({
            url: `${process.env.REACT_APP_SERVER_URL}/api/Product/GetProductList`,
            method: 'GET',
        }).then((response) => {
            const formattedData = response.data.data.map(item => ({
                label: item.productName,
                value: item.productId
            }));
            setProductList(formattedData)
        })
            .catch((error) => {
            })
    };
    const projectlist = async (value) => {
        axios({
            url: `${process.env.REACT_APP_SERVER_URL}/api/Project/GetProjectList`,
            method: 'GET',
        }).then((response) => {
            const formattedData = response.data.data.map(item => ({
                label: item.projectName,
                value: item.projectId
            }));
            setProjectList(formattedData)
        })
            .catch((error) => {
            })
    };
    const employeelist = async (value) => {
        axios({
            url: `${process.env.REACT_APP_SERVER_URL}/api/Employee/GetEmployeeList`,
            method: 'GET',
        }).then((response) => {
            const formattedData = response.data.data.map(item => ({
                label: item.employeeName,
                value: item.employeeId
            }));
            setEmployeeList(formattedData)
        })
            .catch((error) => {
            })
    };
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([])
    const onSubmit = async (value) => {
        setLoading(true)
        axios({
            url: `${process.env.REACT_APP_SERVER_URL}/api/Project/GetProjectList?DepartmentName=${value?.projectName ? value?.projectName : ''}`,
            method: 'GET',
        }).then((response) => {
            setLoading(false)
            setData(response.data.data)
        })
            .catch((error) => {
                setLoading(false)
            })
    };
    const approvetheMR = () => {
        axios({
            url: `${process.env.REACT_APP_SERVER_URL}/api/MR/ApprovalMR`,
            method: 'POST',
            data: {
                userId: "string",
                deparmentId: 0,
                approvalStatus: 1,
                mrId: parseInt(location.state.id),
                approvalDate: new Date()
            },
        }
        ).then((response) => {
            toast.success("MR Approved successfully ")
            navigate('/MR-list')
        })
            .catch((error) => {
                toast.error("something went wrong")
                console.log(error);
            })
    }
    const rejecttheMR = () => {
        axios({
            url: `${process.env.REACT_APP_SERVER_URL}/api/MR/ApprovalMR`,
            method: 'POST',
            data: {
                userId: "string",
                deparmentId: 0,
                approvalStatus: 0,
                mrId: parseInt(location.state.id),
                approvalDate: new Date()
            },
        }
        ).then((response) => {
            toast.success("MR Rejected successfully ")
            navigate('/MR-list')
        })
            .catch((error) => {
                toast.error("something went wrong")
                console.log(error);
            })
    }
    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            backgroundColor: state.isDisabled ? 'white' : provided.backgroundColor,
        }),
    };
    return (
        <>
            <div className="container-fluid">
                <div className="row" >
                    <div className="col-xxl-12">
                        <div className="card custom-card" id="cart-container-delete">
                            <div className="card-header">
                                <div className="card-title">
                                    Approve MR
                                </div>
                            </div>
                            <div className="pt-3">
                                <Row>
                                    <Form.Group as={Col} md="4" controlId="validationCustom01">
                                        <Select
                                            value={selectedProject}
                                            onChange={handleSelectproject}
                                            options={projectList}
                                            isSearchable
                                            placeholder="Search Project"
                                            isDisabled
                                            styles={customStyles}
                                        />
                                        <p className="errorMsg pl-3 mt-2">{errors.designationName ? errors.designationName.message : ''}</p>
                                    </Form.Group>
                                    <Form.Group as={Col} md="4" controlId="validationCustom02">
                                        <Select
                                            value={selectedEmployee}
                                            onChange={handleSelectEmploee}
                                            options={employeeList}
                                            isSearchable
                                            placeholder="Search Employee"
                                            isDisabled
                                            styles={customStyles}
                                        />
                                        <p className="errorMsg pl-3 mt-2">{errors.designationName ? errors.designationName.message : ''}</p>
                                    </Form.Group>
                                    <Form.Group as={Col} md="4" controlId="validationCustom02">
                                        <Select
                                            value={selectedMrcategory}
                                            onChange={handleSelectMr}
                                            options={mrcategory}
                                            isSearchable
                                            placeholder="Search Category"
                                            isDisabled
                                            styles={customStyles}
                                        />
                                        <p className="errorMsg pl-3 mt-2">{errors.designationName ? errors.designationName.message : ''}</p>
                                    </Form.Group>
                                </Row>
                            </div>
                            <div className="pt-3">
                                <Row>
                                    <Form.Group as={Col} md="4" controlId="validationCustom01">
                                        <div style={{ border: '1px solid #f3f3f3', width: '100%', padding: 8 }}>
                                            <input type="text" placeholder="Mr number" name="mrNumber" value={mrnum} onChange={mrNumber} disabled />
                                        </div>
                                    </Form.Group>
                                    <Form.Group as={Col} md="4" controlId="validationCustom02">
                                        <div style={{ border: '1px solid #f3f3f3', width: '100%', padding: 8 }}>
                                            <DatePicker selected={startDate1} onChange={(date) => setStartDate1(date)} disabled />
                                        </div>
                                    </Form.Group>
                                    <Form.Group as={Col} md="4" controlId="validationCustom02">
                                        <Select
                                            isDisabled
                                            value={selectedPrio}
                                            onChange={handleSelectPrio}
                                            options={[{
                                                label: 'major',
                                                value: 0
                                            }, {
                                                label: 'minor',
                                                value: 1
                                            }]}
                                            isSearchable
                                            placeholder="Search Priority"
                                            styles={customStyles}
                                        />
                                        <p className="errorMsg pl-3 mt-2">{errors.designationName ? errors.designationName.message : ''}</p>
                                    </Form.Group>
                                </Row>
                            </div>
                            <div className="row">
                                <div className="table-responsive col-8 ">
                                    <table className="gridjs-table mt-3">
                                        <thead>
                                            <tr>
                                                <th className='gridjs-th'>Product</th>
                                                <th className='gridjs-th'>Unit</th>
                                                <th className='gridjs-th'>Quantity</th>
                                                <th className='gridjs-th'>Price</th>
                                                <th className='gridjs-th'>Date</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {addProductList?.map((contact, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td className="gridjs-td">{contact.productName}</td>
                                                        <td className="gridjs-td">{contact.unitName}</td>
                                                        <td className="gridjs-td">{contact.qty}</td>
                                                        <td className="gridjs-td">{contact.prodcost ? contact.prodcost : contact.prodCost}</td>
                                                        <td className="gridjs-td">{contact.requireddate ? contact.requireddate?.toISOString().split('T')[0] : contact.requiredDate ? new Date(contact.requiredDate)?.toISOString().split('T')[0] : new Date()?.toISOString().split('T')[0]}</td>
                                                    </tr>
                                                )
                                            })}

                                        </tbody>
                                    </table>
                                    <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 10, marginBottom: 10 }}>

                                    </div>
                                    <div className="row card-body">
                                <div className="col-6 p-1">
                                    <div className="row">
                                        <div className="col-5">
                                            <Button className="mr-3 btn btn-danger btn-raised-shadow btn-wave waves-effect waves-light btn-w-sm" type="button" onClick={() => rejecttheMR()} >Reject</Button>
                                        </div>
                                        <div className="col-5">
                                            <Button className="btn btn-success btn-w-sm btn-wave waves-effect waves-light" type="button" onClick={() => approvetheMR()}>Approve</Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                                </div>
                                <div className="col-4">
                                    <Card className="mt-3" style={{height:'auto'}}>
                                        <Card.Header>
                                            <Card.Title>
                                                Approve Details
                                            </Card.Title>
                                        </Card.Header>
                                        <Card.Body className="p-0" style={{ maxHeight: 300 }}>
                                            <ul className="recent-activity" key={Math.random()}>
                                                {approvallist?.map((Product, index) => (
                                                    <li className="mb-1 mt-3 border-bottom">
                                                        <span className="font-weight-normal1 fs-13">{Product.departmentName}</span>
                                                        <span className="text-muted fs-12 float-end">{Product.approvalDate?.slice(0, 10)}</span>

                                                        <p className="main-header-container"> <span className="text-muted fs-12 mt-1"> {Product.employeeName}</span> 
                                                        <span className={Product.approvalStatus == 1 ? 'badge bg-success' : 'badge bg-danger'}>{Product.approvalStatus == 1 ? 'Approved' : 'Rejected'}</span>
                                                         </p>
                                                    </li>
                                                ))}
                                            </ul>
                                        </Card.Body>
                                    </Card>
                                </div>
                            </div>
                            {/* <div className="row card-body">
                                <div className="col-4"></div>
                                <div className="col-4 p-5">
                                    <div className="row">
                                        <div className="col-5">
                                            <Button className="mt-3" type="button" style={{ width: '100%', backgroundColor: 'red' }} onClick={()=>rejecttheMR()} >Reject</Button>
                                        </div>
                                        <div className="col-2"></div>
                                        <div className="col-5">
                                            <Button className="mt-3" type="button" style={{ width: '100%', backgroundColor: 'green' }} onClick={()=>approvetheMR()}>Approve</Button>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-4"></div>
                            </div> */}
                        </div>
                    </div>
                </div>
                <div className="row card custom-card">

                </div>

            </div>
            <ToastContainer />
        </>
    )
}

export default ApproveMr
