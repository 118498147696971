import React, { FC, Fragment, useState, useEffect } from 'react';
import { Button, Card, Col, InputGroup, Row, Form, Alert, Nav, Tab } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useLocation, useSearchParams } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import PageHeader from '../../layouts/pageheader';
import { Oval } from "react-loader-spinner";
import Select from 'react-select';
import DatePicker from "react-datepicker";

function AddTrip() {
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate();
    const location = useLocation();
    const [proData,setProData] = useState([])
    const schema = yup.object().shape({
    })
    const {
        register,
        handleSubmit,
        reset,
        setValue,
        clearErrors,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    });
    const [startDate, setStartDate] = useState(new Date());
    const [tripDate, setTripDate] = useState(new Date());
    const [paymentDate, setPaymentDate] = useState(new Date());
    const [company, setCompany] = useState([]);
    const [selectedCompany, setSelectedCompany] = useState(null);
    const [typedCompany, setTypedCompany] = useState('');
    const [inputValue, setInputValue] = useState('');
    const [isSelectMode, setIsSelectMode] = useState(true);
    const [hiddenTextValue, setHiddenTextValue] = useState('');
    const onShowCompany = async (value) => {
        axios({
            url: `${process.env.REACT_APP_SERVER_URL}/api/Trip/GetTripCompanyList`,
            method: 'GET',
        }).then((response) => {
            setProData(response.data.data)
            const formattedData = response.data.data.map(item => ({
                label: item.company
            }));
            setCompany(formattedData)
        })
            .catch((error) => {
            })
    };

    const handleTypeCompany = (input, { action }) => {
        if (action === 'input-change') {
          setInputValue(input);
          // Update the value of the hidden textbox here
          setHiddenTextValue(input);
        }
      };
    
    const onSubmit = async (data) => {
        
        const upload = {
            userId: "string",
            Company: selectedCompany?.label || data?.company,
            TripDate: tripDate,
            Driver: data?.driver,
            DriverMobile: data?.mobile,
            AwbNo: data?.awbno,
            Remarks: data?.remarks,
            LoadingFrom: data?.loadingFrom,
            DeliveryTo: data?.deliveryTo,
            VehicleType: data?.vehicletype,
            NoOfTrips: data?.nooftrips,
            PaymentStatus: data?.paymentstatus,
            TripCharge: data?.tripcharge,
            InvoiceAmount: data?.invoiceamount,
            Commission: data?.commission,
            PaymentDate: data?.paymentDate,
            Detention: data?.detention,
            ExtraDelivery: data?.extra_delivery,

            Id: 0
        }
        setLoading(true)
        try {
            const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/Trip/CreateTrip`, upload);
            if (response.data.status == 1) {
                toast.success(response.data.message);
                navigate('/trip-list')
                setLoading(false)
            }

        } catch (error) {
            setLoading(false)
            console.log(error);
        }
    }
    useEffect(() => {
        onShowCompany()
        
    }, [])

    const handleTextareaKeyDown = (event) => {
        // Check if Enter key is pressed (keyCode 13) and if the event doesn't have the 'Shift' key
        if (event.keyCode === 13 && !event.shiftKey) {
            event.preventDefault(); // Prevent adding a new line
            handleSubmit(onSubmit); // Submit the form
        }
    };

    const handleSelectCompany = (selectedOption) => {
        console.log('AS'+selectedOption.label)
        setTypedCompany('');
        setSelectedCompany(selectedOption);
       
    };
    const onMenuClose = () => {
        // Clear typed company when the menu closes
        setTypedCompany('');
      };

      const toggleMode = () => {
        setIsSelectMode(!isSelectMode);
      };
  return (
    <Fragment>
            <Fragment>
                <div className='page-header'>
                    <div className="page-leftheader">
                        <h4 className="page-title mb-0">Add Trip</h4>
                    </div>
                    <div className="page-rightheader">
                        <div className='d-flex align-items-center'>

                        </div>
                    </div>
                </div>
            </Fragment>
            <Row>
                <Col xl={12} md={12} sm={12}>
                </Col>
                <Col xl={12} md={12} sm={12}>
                    <Card>
                        <Card.Body>
                            <Form onSubmit={handleSubmit(onSubmit)}>
                            <div className="row mt-1">
                            <div className="col-3">
                                    <label>Date</label>
                                    <div style={{ border: '1px solid #f3f3f3', width: '100%', padding: 8 }}>
                                        <DatePicker selected={tripDate} onChange={(date) => setTripDate(date)} />
                                    </div>
                                </div>
                                <Form.Group as={Col} md="5" controlId="validationCustom01">
      <Form.Label>Company</Form.Label>
                               
                                {isSelectMode ? (
        <Select
          value={selectedCompany}
          onChange={handleSelectCompany}
          options={company}
          //onInputChange={handleTypeCompany}
          isSearchable                                                                                              
          placeholder="Search Company"
         // inputValue={inputValue}

        />
      ) : (
        <Form.Control
          type="text"
          value={hiddenTextValue}
          {...register("company")}
          onChange={(e) => setHiddenTextValue(e.target.value)}
        />
      )}
      
      </Form.Group>
         <div className="col-1">
            <br/>
         <div></div>
         <Button className="mx-2"  onClick={toggleMode}>{loading ?
                                        <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "20px" }}>
                                            <Oval

                                                heigth="15"
                                                width="20"
                                                color='#3f12c4'
                                                arialLabel='loading'
                                            />
                                        </div> : "New"}</Button>
                                 
      </div>

                                   
                                <Form.Group as={Col} md="3" controlId="validationCustom01">
                                <label>Awb No</label>
                                    <Form.Control
                                        type="text"
                                        name='awbno'
                                        placeholder="Awb No"
                                        {...register("awbno")}
                                    />
                                    <p className="errorMsg pl-3 mt-2">{errors.awbno ? errors.awbno.message : ''}</p>
                                </Form.Group>

                                </div>
                                <div className="row mt-1">
                                <Form.Group as={Col} md="4" controlId="validationCustom01">
                                <label>Driver</label>
                                    <Form.Control
                                        type="text"
                                        name='driver'
                                        placeholder="Driver"
                                        {...register("driver")}
                                    />
                                    <p className="errorMsg pl-3 mt-2">{errors.driver ? errors.driver.message : ''}</p>
                                </Form.Group>

                                <Form.Group as={Col} md="4" controlId="validationCustom01">
                                <label>Mobile</label>
                                    <Form.Control
                                        type="number"
                                        name='mobile'
                                        placeholder="Mobile"
                                        {...register("mobile")}
                                    />
                                    <p className="errorMsg pl-3 mt-2">{errors.mobile ? errors.mobile.message : ''}</p>
                                </Form.Group>

                               

                                <Form.Group as={Col} md="4" controlId="validationCustom01">
                                <label>Vehicle Type</label>
                                    <Form.Control
                                        type="text"
                                        name='vehicletype'
                                        placeholder="Vehicle Type"
                                        {...register("vehicletype")}
                                    />
                                    <p className="errorMsg pl-3 mt-2">{errors.vehicletype ? errors.vehicletype.message : ''}</p>
                                </Form.Group>
                                </div>

                                <div className="row mt-1">

                                <Form.Group as={Col} md="6"    controlId="validationCustom01">
                                <label>Remarks</label>
                                    <Form.Control
                                        type="textarea"
                                        name='remarks'
                                        placeholder="Remarks"
                                        {...register("remarks")}
                                        // rows={3}
                                        // style={{ height: '100px' }} 
                                        // onKeyDown={handleTextareaKeyDown}
                                     
                                    />
                                    <p className="errorMsg pl-3 mt-2">{errors.remarks ? errors.remarks.message : ''}</p>
                                </Form.Group>
                                <Form.Group as={Col} md="3" controlId="validationCustom01">
                                <label>Loading From</label>
                                    <Form.Control
                                        type="text"
                                        name='loadingFrom'
                                        placeholder="Loading From"
                                        {...register("loadingFrom")}
                                    />
                                    <p className="errorMsg pl-3 mt-2">{errors.loadingFrom ? errors.loadingFrom.message : ''}</p>
                                </Form.Group>
                                <Form.Group as={Col} md="3" controlId="validationCustom01">
                                <label>Delivery To</label>
                                    <Form.Control
                                        type="text"
                                        name='deliveryTo'
                                        placeholder="Delivery To"
                                        {...register("deliveryTo")}
                                    />
                                    <p className="errorMsg pl-3 mt-2">{errors.deliveryTo ? errors.deliveryTo.message : ''}</p>
                                </Form.Group>
                                
                              </div>


                              <div className="row mt-1">
                                <Form.Group as={Col} md="2" controlId="validationCustom01">
                                <label>No. of Trips</label>
                                    <Form.Control
                                        type="number"
                                        name='nooftrips'
                                        placeholder="No. of Trips"
                                        {...register("nooftrips")}
                                    />
                                    <p className="errorMsg pl-3 mt-2">{errors.nooftrips ? errors.nooftrips.message : ''}</p>
                                </Form.Group>

                                <Form.Group as={Col} md="2" controlId="validationCustom01">
                                <label>Payment Status</label>
                                    <Form.Control
                                        type="text"
                                        name='paymentstatus'
                                        placeholder="Payment Status"
                                        {...register("paymentstatus")}
                                    />
                                    <p className="errorMsg pl-3 mt-2">{errors.paymentstatus ? errors.paymentstatus.message : ''}</p>
                                </Form.Group>
                                <div className="col-2">
                                    <label>Payment Date</label>
                                    <div style={{ border: '1px solid #f3f3f3', width: '100%', padding: 4 }}>
                                        <DatePicker selected={paymentDate} onChange={(date) => setPaymentDate(date)} />
                                    </div>
                                </div>

                                <Form.Group as={Col} md="2" controlId="validationCustom01">
                                <label>Trip Charge</label>
                                    <Form.Control
                                        type="number"
                                        name='tripcharge'
                                        placeholder="Trip Charge"
                                        {...register("tripcharge")}
                                    />
                                    <p className="errorMsg pl-3 mt-2">{errors.tripcharge ? errors.tripcharge.message : ''}</p>
                                </Form.Group>
                                <Form.Group as={Col} md="2" controlId="validationCustom01">
                                <label>Invoice Amt</label>
                                    <Form.Control
                                        type="number"
                                        name='invoiceamount'
                                        placeholder="Invoice Amount"
                                        {...register("invoiceamount")}
                                    />
                                    <p className="errorMsg pl-3 mt-2">{errors.invoiceamount ? errors.invoiceamount.message : ''}</p>
                                </Form.Group>

                                <Form.Group as={Col} md="2" controlId="validationCustom01">
                                <label>Commission</label>
                                    <Form.Control
                                        type="number"
                                        name='commission'
                                        placeholder="Commission"
                                        {...register("commission")}
                                    />
                                    <p className="errorMsg pl-3 mt-2">{errors.commission ? errors.commission.message : ''}</p>
                                </Form.Group>
                               

                                </div>

                                <div className="row mt-1">
                                <Form.Group as={Col} md="2" controlId="validationCustom01">
                                <label>Detention</label>
                                    <Form.Control
                                        type="number"
                                        name='Detention'
                                        placeholder="Detention"
                                        {...register("detention")}
                                    />
                                    <p className="errorMsg pl-3 mt-2">{errors.detention ? errors.detention.message : ''}</p>
                                </Form.Group>


                                <Form.Group as={Col} md="2" controlId="validationCustom01">
                                <label>Extra Delivery</label>
                                    <Form.Control
                                        type="number"
                                        name='extra_delivery'
                                        placeholder="Extra Delivery"
                                        {...register("extra_delivery")}
                                    />
                                    <p className="errorMsg pl-3 mt-2">{errors.extra_delivery ? errors.extra_delivery.message : ''}</p>
                                </Form.Group>

                                    </div>
                              
                       
                                <div className="d-flex justify-content-center formfilter mt-5">
                                    <Button type="submit" className="mx-2">{loading ?
                                        <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "25px" }}>
                                            <Oval

                                                heigth="20"
                                                width="20"
                                                color='#12C412'
                                                arialLabel='loading'
                                            />
                                        </div> : "Submit"}</Button>
                                </div>
                            </Form>
                        </Card.Body>
                    </Card>
                </Col>
                <Col xl={4} md={12} sm={12}>
                </Col>
            </Row>

        </Fragment>
  )
}

export default AddTrip
