import React, { FC, Fragment, useState, useEffect } from 'react';
import { Button, Card, Col, InputGroup, Row, Form, Alert, Nav, Tab } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useLocation, useSearchParams } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import PageHeader from '../../../layouts/pageheader';
import { Oval } from "react-loader-spinner";
import Select from 'react-select';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function EditProjects() {
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate();
    const location = useLocation();
    const [selectdStatus, setSelectedStatus] = useState([])
    const [startDate, setStartDate] = useState(new Date());
    const [startDate1, setStartDate1] = useState(new Date());
    const schema = yup.object().shape({

    })
    const {
        register,
        handleSubmit,
        reset,
        setValue,
        clearErrors,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    });
    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                const response = await axios.get(
                    `${process.env.REACT_APP_SERVER_URL}/api/Project/GetProjectById?ProjectId=${parseInt(location.state.id)}`
                );
                const companyData = response.data.data;
                setValue("projectName", companyData.projectName);
                setValue("projectCode", companyData.projectCode);
                setValue("projectDescription", companyData.projectDescription);
                setSelectedStatus({
                    label:companyData.projectStatus===0?'Active':'In Active',
                    value:companyData.projectStatus
                })
                setStartDate(new Date(companyData.projectStartDate))
                setStartDate1(new Date(companyData.projectEndDate))
                setLoading(false);
            } catch (error) {
                console.error("Error fetching company data:", error);
                setLoading(false)
            }
        };
        fetchData();
    }, [location.state.id, setValue, setLoading]);
    const onSubmit = async (data) => {
        const upload = {
            userId: "string",
            projectName: data?.projectName,
            projectCode: data?.projectCode,
            projectDescription: data?.projectDescription,
            projectStartDate: startDate,
            projectEndDate: startDate1,
            projectStatus: selectdStatus?.value,
            projectId: parseInt(location.state.id)
        }
        setLoading(true)
        try {
            const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/Project/UpdateProject`, upload);
            if (response.data.status == 1) {
                toast.success(response.data.message);
                navigate('/project-list')
                setLoading(false)
            }

        } catch (error) {
            setLoading(false)
            console.log(error);
        }
    }
    const [img, setImg] = useState()
    const handleImageChange = (e) => {
        setImg(e.target.files[0])

    };
    const handleSelectProject = (selectedOption) => {
        setSelectedStatus(selectedOption)
    }
    return (
        <Fragment>
        <Fragment>
            <div className='page-header'>
                <div className="page-leftheader">
                    <h4 className="page-title mb-0">Edit Project</h4>
                </div>
                <div className="page-rightheader">
                    <div className='d-flex align-items-center'>

                    </div>
                </div>
            </div>
        </Fragment>
        <Row>
            <Col xl={4} md={12} sm={12}>
            </Col>
            <Col xl={4} md={12} sm={12}>
                <Card>
                    <Card.Body>
                        <Form onSubmit={handleSubmit(onSubmit)}>
                            <Form.Group as={Col} md="12" controlId="validationCustom01">
                                <Form.Control
                                    type="text"
                                    name='projectCode'
                                    placeholder="projectCode
                            "
                                    {...register("projectCode")}
                                />
                                <p className="errorMsg pl-3 mt-2">{errors.projectCode ? errors.projectCode.message : ''}</p>
                            </Form.Group>
                            <Form.Group as={Col} md="12" controlId="validationCustom01">
                                <Form.Control
                                    type="text"
                                    name='projectName'
                                    placeholder="projectName"
                                    {...register("projectName")}
                                />
                                <p className="errorMsg pl-3 mt-2">{errors.projectName ? errors.projectName.message : ''}</p>
                            </Form.Group>

                            <Form.Group as={Col} md="12" controlId="validationCustom01">
                                <Form.Control
                                    type="text"
                                    name='projectDescription'
                                    placeholder="projectDescription"
                                    {...register("projectDescription")}
                                />
                                <p className="errorMsg pl-3 mt-2">{errors.projectDescription ? errors.projectDescription.message : ''}</p>
                            </Form.Group>
                            <Form.Group as={Col} md="12" controlId="validationCustom01">
                                <label>Project Start Date</label>
                                <div style={{ border: '1px solid #f3f3f3', width: '100%', padding: 8 }}>
                                    <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} />
                                </div>
                                <p className="errorMsg pl-3 mt-2">{errors.projectStartDate ? errors.projectStartDate.message : ''}</p>
                            </Form.Group>
                            <Form.Group as={Col} md="12" controlId="validationCustom01">
                                <label>Project End Date</label>
                                <div style={{ border: '1px solid #f3f3f3', width: '100%', padding: 8 }}>
                                    <DatePicker selected={startDate1} onChange={(date) => setStartDate1(date)} />
                                </div>
                                <p className="errorMsg pl-3 mt-2">{errors.company_Currency ? errors.company_Currency.message : ''}</p>
                            </Form.Group>
                            <Form.Group as={Col} md="12" controlId="validationCustom01">
                                <Select
                                    value={selectdStatus}
                                    onChange={handleSelectProject}
                                    options={[{
                                        label: 'Active',
                                        value: 1
                                    }, {
                                        label: 'In Active',
                                        value: 0
                                    }]}
                                    isSearchable={false}
                                    placeholder="Status"
                                />
                                <p className="errorMsg pl-3 mt-2">{errors.projectStatus ? errors.projectStatus.message : ''}</p>
                            </Form.Group>
                            <div className="d-flex justify-content-center formfilter mt-5">
                                <Button type="submit" className="mx-2">{loading ?
                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "25px" }}>
                                        <Oval

                                            heigth="20"
                                            width="20"
                                            color='#12C412'
                                            arialLabel='loading'
                                        />
                                    </div> : "Submit"}</Button>
                            </div>
                        </Form>
                    </Card.Body>
                </Card>
            </Col>
            <Col xl={4} md={12} sm={12}>
            </Col>
        </Row>

    </Fragment>
    )
}

export default EditProjects
