import React, { FC, Fragment, useState, useEffect } from 'react';
import { Button, Card, Col, InputGroup, Row, Form, Alert, Nav, Tab } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useLocation, useSearchParams } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import PageHeader from '../../layouts/pageheader';
import { Oval } from "react-loader-spinner";
import Select from 'react-select';
import DatePicker from "react-datepicker";

function EditReceipt() {
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate();
    const location = useLocation();
    const schema = yup.object().shape({

    })
    const {
        register,
        handleSubmit,
        reset,
        setValue,
        clearErrors,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    });
    const [startDate, setStartDate] = useState(new Date());
   

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                const response = await axios.get(
                    `${process.env.REACT_APP_SERVER_URL}/api/Receipt/GetReceiptById?Id=${parseInt(location.state.id)}`
                );

                const companyData = response.data.data;
            
                setValue("amount", companyData.amount);
                setValue("receiptDate", companyData.receiptDate);
                setValue("remarks", companyData.remarks);
                setLoading(false);
            } catch (error) {
                console.error("Error fetching company data:", error);
                setLoading(false)
            }
        };
        fetchData();
    }, [location.state.id, setValue, setLoading]);
    const onSubmit = async (data) => {
        const upload = {
            userId: "string",
            amount: data?.amount,
            receiptDate: startDate,
            remarks: data?.remarks,
            Id: parseInt(location.state.id),
        }
        setLoading(true)
        try {
            const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/Receipt/UpdateReceipt`, upload);
            if (response.data.status == 1) {
                toast.success(response.data.message);
                navigate('/receipt-list')
                setLoading(false)
            }

        } catch (error) {
            setLoading(false)
            console.log(error);
        }
    }
    const [img, setImg] = useState()
    const handleImageChange = (e) => {
        setImg(e.target.files[0])

    };



    return (
        <Fragment>
            <Fragment>
                <div className='page-header'>
                    <div className="page-leftheader">
                        <h4 className="page-title mb-0">Edit Receipt</h4>
                    </div>
                    <div className="page-rightheader">
                        <div className='d-flex align-items-center'>

                        </div>
                    </div>
                </div>
            </Fragment>
            <Row>
                <Col xl={4} md={12} sm={12}>
                </Col>
                <Col xl={4} md={12} sm={12}>
                    <Card>
                        <Card.Body>
                        <Form onSubmit={handleSubmit(onSubmit)}>
                              
                             
                                
                              <Form.Group as={Col} md="12" controlId="validationCustom01">
                                  <Form.Control
                                      type="number"
                                      name='amount'
                                      placeholder="Amount"
                                      {...register("amount")}
                                  />
                                  <p className="errorMsg pl-3 mt-2">{errors.amount ? errors.amount.message : ''}</p>
                              </Form.Group>

                              <Form.Group as={Col} md="2" controlId="validationCustom02">
                                      <div style={{ border: '1px solid #f3f3f3', width: '100%', padding: 8 }}>
                                          <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} />
                                      </div>
                                  </Form.Group>

                              <Form.Group as={Col} md="12" controlId="validationCustom01">
                                  <Form.Control
                                      type="text"
                                      name='remarks'
                                      placeholder="Remarks"
                                      {...register("remarks")}
                                  />
                                  <p className="errorMsg pl-3 mt-2">{errors.remarks ? errors.remarks.message : ''}</p>
                              </Form.Group>
                              
                            
                            
                     
                              <div className="d-flex justify-content-center formfilter mt-5">
                                  <Button type="submit" className="mx-2">{loading ?
                                      <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "25px" }}>
                                          <Oval

                                              heigth="20"
                                              width="20"
                                              color='#12C412'
                                              arialLabel='loading'
                                          />
                                      </div> : "Submit"}</Button>
                              </div>
                          </Form>
                        </Card.Body>
                    </Card>
                </Col>
                <Col xl={4} md={12} sm={12}>
                </Col>
            </Row>

        </Fragment>
    )
}

export default EditReceipt
